//#region IMPORT

import { ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BankAccountModel } from "./bankaccount.model";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";
import { SubmissionVideModel } from "./submissionvide.model";

//#endregion


//#region CLASS

export class SubmissionDetailModel extends BaseModel
{
	ID?: number;
	SubmissionID?: number;
	Title?: string;
	Description?: string;
	BeneficiaryName?: string;
	BankAccountToken?: string;
	Amount?: number;

	listModelSubmissionVide?: Array<SubmissionVideModel>;
	modelBankAccount?: BankAccountModel;

	modelSubmissionVide?: SubmissionVideModel;

	constructor()
	{
		super();
		this.Amount = 0;
		this.modelSubmissionVide = new SubmissionVideModel();
		this.listModelSubmissionVide = [];
	}


	//#region VALIDATION

	validateAdd(enumPaymentType: ENUM_PAYMENT_TYPE): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission detail");

		if (enumPaymentType === ENUM_PAYMENT_TYPE.Cash)
		{
			if (this.BeneficiaryName == null || this.BeneficiaryName === undefined || this.BeneficiaryName === "")
			{
				modelResponse.MessageContent = "Nama penerima tidak boleh kosong!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (enumPaymentType === ENUM_PAYMENT_TYPE.Transfer)
		{
			if (this.BankAccountToken == null || this.BankAccountToken === undefined || this.BankAccountToken === "")
			{
				modelResponse.MessageContent = "Penerima tidak boleh kosong!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddModelSubmissionVide(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission detail");

		if(this.listModelSubmissionVide == null || this.listModelSubmissionVide === undefined)
		{
			modelResponse.MessageContent = "List vide tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelBankAccount(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission detail");

		if (this.modelBankAccount == null || this.modelBankAccount === undefined)
		{
			modelResponse.MessageContent = "Akun bank tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateInsert(enumPaymentType: ENUM_PAYMENT_TYPE): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateAdd(enumPaymentType);

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddModelSubmissionVide() ?? new ResponseModel();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let modelResponseSubmissionVide: ResponseModel;

			for (const modelSubmissionVide of this.listModelSubmissionVide ?? [])
			{
				modelResponseSubmissionVide = modelSubmissionVide.validateInsert();

				if (modelResponseSubmissionVide.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSubmissionVide;
				}
				else
				{

				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setListModelSubmissionVide(): void
	{
		if (this.listModelSubmissionVide == null || this.listModelSubmissionVide === undefined)
		{

		}
		else
		{
			if (this.listModelSubmissionVide.length > 0)
			{
				const arrayModelSubmissionVideTemporary: Array<SubmissionVideModel> = this.listModelSubmissionVide;
				this.listModelSubmissionVide = [];

				let modelSubmissionVide: SubmissionVideModel;

				for (const modelSubmissionVideTemporary of arrayModelSubmissionVideTemporary)
				{
					modelSubmissionVide = new SubmissionVideModel();
					modelSubmissionVide.setModelFromObject(modelSubmissionVideTemporary);
					this.listModelSubmissionVide.push(modelSubmissionVide);
				}
			}
			else
			{

			}
		}
	}

	//#endregion
}

//#endregion