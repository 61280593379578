//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { LetterGeneralModel } from "../models/lettergeneral.model";
import { SubmissionDocumentModel } from "../models/submissiondocument.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class SubmissionService extends BaseService
{

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#region INSERT

	insertSubmission(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_INSERTSUBMISSION);
	}

	//#endregion


	//#region SELECT

	selectSubmissionByAttributesForMaker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORMAKER);
	}

	selectSubmissionByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORCHECKER);
	}

	selectSubmissionByAttributesForSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORSIGNER);
	}

	selectSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONBYTOKEN);
	}

	selectSubmissionQRByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_SELECTSUBMISSIONQRBYTOKEN);
	}

	//#endregion


	//#region UPDATE

	updateSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_UPDATESUBMISSIONBYTOKEN);
	}

	//#endregion


	//#region DELETE

	deleteSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DELETESUBMISSIONBYTOKEN);
	}

	//#endregion


	//#region APPROVAL

	checkSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_CHECKSUBMISSIONBYTOKEN);
	}

	signSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_SIGNSUBMISSIONBYTOKEN);
	}

	rejectSubmissionByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_REJECTSUBMISSIONBYTOKEN);
	}

	//#endregion


	//#region UPLOAD

	uploadSubmission(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel)
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_UPLOADSUBMISSION);
	}

	//#endregion


	//#region DOWNLOAD

	downloadSubmissionAttachment(interfaceGeneralService: GeneralServiceInterface, modelSubmissionDocument: SubmissionDocumentModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelSubmissionDocument), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONDOCUMENT);
	}

	downloadSubmissionLetter(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSION);
	}

	downloadSubmissionTransferReceiptByLetterGeneralToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral) ,WebAddressConstant.STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONTRANSFERRECEIPTBYLETTERGENERALTOKEN);
	}

	//#endregion
}

//#endregion