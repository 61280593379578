//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { DashboardLetterGeneralSummaryModel } from "../models/dashboardlettergeneralsummary.model";
import { TableModel } from "../models/bases/table.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region  CLASSS

export class ReportService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectLetterGeneralByAttributesForDeclarationForCompanySecretariat(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DASHBOARD_SELECTLETTERGENERALBYATTRIBUTESFORDECLARATIONFORCOMPANYSECRETARIAT);
	}

	selectLetterGeneralByAttributesForSubmissionForCompanySecretariat(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DASHBOARD_SELECTLETTERGENERALBYATTRIBUTESFORSUBMISSIONFORCOMPANYSECRETARIAT);
	}

	selectLetterGeneralForDeclarationForCompanySecretariat(interfaceGeneralService: GeneralServiceInterface, modelDashboardLetterGeneralSummary: DashboardLetterGeneralSummaryModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardLetterGeneralSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTLETTERGENERALFORDECLARATIONFORCOMPANYSECRETARIAT);
	}

	selectLetterGeneralForSubmissionForCompanySecretariat(interfaceGeneralService: GeneralServiceInterface, modelDashboardLetterGeneralSummary: DashboardLetterGeneralSummaryModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDashboardLetterGeneralSummary), WebAddressConstant.STRING_URL_DASHBOARD_SELECTLETTERGENERALFORSUBMISSIONFORCOMPANYSECRETARIAT);
	}

	//#endregion
}

//#endregion
