//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class AccountChargeModel extends BaseModel
{
	ID?: number;
	Code?: string;
	Name?: string;
	Description?: string;
}

//#endregion