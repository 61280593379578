//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToBroadcastURL, ConvertToProductURL, ConvertToUserURL } from "./hyperlink.pipe";
import { ConvertByteForPreview, ConvertCapitalizeEachWord, ConvertDateToDay, ConvertEmptyToDash, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, ConvertToTime, CurrencyValue, InitiateWithoutUTC } from "./converter.pipe";
import { TrimShort, TrimShortest } from "./trim.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			InitiateWithoutUTC,
			CurrencyValue,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			TrimShort,
			TrimShortest,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertToUserURL,
			ConvertToTime,
			ConvertCapitalizeEachWord,
			ConvertDateToDay
		],
		exports:
		[
			ConvertToProductURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertEmptyToDash,
			InitiateWithoutUTC,
			CurrencyValue,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			TrimShort,
			TrimShortest,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			ConvertToBroadcastURL,
			ConvertToUserURL,
			ConvertToTime,
			ConvertCapitalizeEachWord,
			ConvertDateToDay
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion