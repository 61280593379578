/* eslint-disable no-shadow */
//#region ENUM

//#region STATE

/*
	ENUM_REGISTRATION_STATE - START
	Description : Set of constants for registration state.
	Author :  Andri Septiawan.
	Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:1.
*/

export enum ENUM_REGISTRATION_STATE
{
	Success = 0,
	Verified = 1,
	NotVerified = 2
}

/* ENUM_REGISTRATION_STATE - END */

/*
		ENUM_VERIFICATION_STATE - START
		Description : Set of constants for registration state.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VERIFICATION_STATE
	{
		Verified = 0,
		OnProcess = 1,
		NotVerified = 2,
		Rejected = 3
	}

/* ENUM_VERIFICATION_STATE - END */

/*
		ENUM_GENERAL_STATE - START
		Description : Set of constants for state general.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENERAL_STATE
	{
		Active = 0,
		Incative = 1
	}

/* ENUM_GENERAL_STATE - END */

/*
		ENUM_RESPONSE_STATE - START
		Description : Enum for response state, this is used for validation response mostly, it will trigger the icon shown in the user interface.
		Author: Andri Septiawan.
		Created on :  Monday, 28 December 2020.             Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RESPONSE_STATE
	{
		Success = 0,
		Warning = 1,
		Fail = 2,
		information = 3,
		Nothing = 4
	}

/* ENUM_RESPONSE_STATE - END */

/*
	ENUM_DOCUMENTSTAMPCOLLECTOR_STATUS - START
	Description : This is used for stamp collector status
	Author: Andryana Baros.
	Created on : Monday, 07 March 2022.        		Updated on : -.
	Created by : Andryana Baros.					Updated by : -.
	Version : 1.1:32.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTOR_STATUS
{
	Success = 0,
	Fail = 1,
	OnProcess = 2,
	Cancel = 3,
}

/* ENUM_DOCUMENTSTAMPCOLLECTOR_STATUS - END */

//#endregion


//#region TYPE

/*
		ENUM_GENDER_TYPE - START
		Description : Set of constants for gender type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_GENDER_TYPE
	{
		Female = 0,
		Male = 1
	}

/* ENUM_GENDER_TYPE - END */

/*
		ENUM_RELIGION_TYPE - START
		Description : Enum for religion state, these religion is based on Indonesia law.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RELIGION_TYPE
	{
		Islam = 0,
		Catholicism = 1,
		Christianity = 2,
		Hinduism = 3,
		Buddhism = 4,
		Confucianism = 5,

	}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_RELIGION_FOLLOWER - START
		Description : Enum for religion state, these religion is based on Indonesia law.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_RELIGION_FOLLOWER
	{
		Moslem = 0,
		Catholic = 1,
		Christian = 2,
		Hindu = 3,
		Buddhist = 4,
		Confucianist = 5,
	}

/* ENUM_RELIGION_FOLLOWER - END */

/*
		ENUM_IDENTIFICATION_TYPE - START
		Description : Enum for the identification type this will trigger how you treat the identification number.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_IDENTIFICATION_TYPE
	{
		IdentityCard = 0,
		DrivingLicense = 1,
		TemporaryStayCard = 2,
		PermanentStayCard = 3,
		Passport = 5
	}

/* ENUM_IDENTIFICATION_TYPE - END */

/*
		ENUM_EDUCATION_TYPE - START
		Description : Enum for education type, these are the main education in Indonesia.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EDUCATION_TYPE
	{
		ElementarySchool = 0,
		JuniorHighSchool = 1,
		SeniorHighSchool = 2,
		AssociateDegree = 3,
		BachelorDegree = 4,
		MasterDegree = 5,
		DoctoralDegree = 6
	}

/* ENUM_EDUCATION_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee type, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Monday, 11 January 2021.          Updated on : Monday, 11 January 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_TYPE
	{
		Volunteer = 0,
		Internship = 1,
		Freelance = 2,
		PartTime = 3,
		OutSource = 4,
		Contract = 5,
		FullTime = 6
	}

/* ENUM_EMPLOYMENT_TYPE - END */

/*
		ENUM_EMPLOYMENT_TYPE - START
		Description : Enum for employee Grade, these are the main employement in company.
		Author: Andri Septiawan.
		Created on : Thursday, 18 February 2021.          Updated on : Thursday, 18 February 2021.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_EMPLOYMENT_GRADE
	{
		Zero = 0,
		One = 1,
		Two = 2,
		Three = 3,
		Four = 4,
		Five = 5,
		Six = 6,
		Seven = 7,
		Eight = 8,
		Nine = 9,
		Ten = 10,
		Eleven = 11,
		Twelve = 12,
		Thirteen = 13,
		Fourteen = 14,
		Fifteen = 15
	}

/* ENUM_EMPLOYMENT_TYPE - END */


/*
		ENUM_SERVER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SERVER_TYPE
	{
		user,
		product,
		Policy
	}

/* ENUM_SERVER_TYPE - END */

/*
		ENUM_REGISTER_TYPE - START
		Description : Enum for server name, this will be use for micro service architecure, to know where the latest token is.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_REGISTER_TYPE
	{
		KenalanMobilePhone = 0,
		Facebook = 1,
		Google = 2,
		Linkedin = 3,
		Apple = 4,
		Twitter = 5,
		Line = 6,
		KakaoTalk = 7,
		KenalanEmail = 8,
	}

/* ENUM_REGISTER_TYPE - END */

/*
		ENUM_SALUTATION_TYPE - START
		Description : Set of constants for salutation type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SALUTATION_TYPE
	{
		Miss,
		Mr,
		Mrs
	}

/* ENUM_SALUTATION_TYPE - END */

/*
		ENUM_PHOTO_TYPE - START
		Description : Set of constants for photo type accross product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PHOTO_TYPE
	{
		BuildingFront,
		BuildingLeft,
		BuildingRight,
		BuildingBack,
		BuildingInterior,
		VehicleFront,
		VehicleLeft,
		VehicleRight,
		VehicleBack,
		VehicleInterior,
		STNK,
		KTP,
		ClientApproval,
		PaymentReceipt,
		Visit,
		OutlookArticleAttachment = 154
	}

/* ENUM_PHOTO_TYPE - END */

/*
		ENUM_ENVIRONMENT_TYPE - START
		Description : Set of constants for environment type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_ENVIRONMENT_TYPE
	{
		Local = 0,
		Development = 1,
		Staging = 2,
		Production = 3,
	}

/* ENUM_ENVIRONMENT_TYPE - END */

/*
		ENUM_PROJECT_TYPE - START
		Description : Set of constants for project type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PROJECT_TYPE
	{
		Anjani,
		Bromo,
		Staging,
		Production
	}

/* ENUM_PROJECT_TYPE - END */

/*
		ENUM_VIRTUALACCOUNT_TYPE - START
		Description : Set of constants for virtual account type.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_VIRTUALACCOUNT_TYPE
	{
		Briva = 0
	}

/* ENUM_VIRTUALACCOUNT_TYPE - END */

/*
		ENUM_PDFPREVIEW_TYPE - START
		Description : Set of constants for pdf preview type, to define what model to open from product.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_PDFPREVIEW_TYPE
	{
		ProductAsriCalculatePremium = 0,
		ProductAsriSPPA = 1,
		ProductOtoCalculatePremium = 2,
		ProductOtoSPPA = 3,
		ProductPersonalAccidentCalculatePremium = 4,
		ProductPersonalAccidentSPPA = 5,
		PolicyOutstanding = 6,
		PolicyRenewal = 7
	}

/* ENUM_PDFPREVIEW_TYPE - END */

/*
		ENUM_LETTER_TYPE - START
		Description : Set of constants for letter type, to define what serial number need to be generated.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          	Updated on : Friday, 16 April 2021.
		Created by : Andri Septiawan.						Updated by : Billy Johannes.
		Version : 1.1:0.
	*/

export enum ENUM_LETTER_TYPE
	{
		DeclarationPayment = 1,
		DeclarationReimburse = 2,
		DeclarationRealizationDownPayment = 3,
		DeclarationRealizationDebt = 4,
		SubmissionDownPayment = 5,
		SubmissionDebt = 6,
		OfficialMemo = 7,
		LetterOut = 8,
		LetterIn = 9,
		Outstanding = 10,
		Renewal = 11
	}

/* ENUM_LETTER_TYPE - END */

/*
		ENUM_BRANCH_TYPE - START
		Description : Set of constants for branch type, to define what branch type included in.
		Author : Billy Johannes.
		Created on : Sunday, 02 May 2020.          	Updated on : Sunday, 02 May 2020.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_BRANCH_TYPE
	{
		Headquarters = 0,
		Branch = 1,
		MRO = 2,
		ROS = 3
	}

/* ENUM_BRANCH_TYPE - END */

/*
	ENUM_PRODUCT_TYPE - START
	Description : Set of constants for product type, to use in search by attributes.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.   Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.      Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_PRODUCT_TYPE
{
	Conventional = 0,
	Special = 1,
	Syariah = 2,
	Customize = 3
}

/* ENUM_PRODUCT_TYPE - END */

/*
		ENUM_MARITAL_STATUS - START
		Description : Set of constants for marital status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_MARITAL_STATUS
	{
		Single = 0,
		Married = 1,
		Divorce = 2,
		Widow = 3,
	}

/* ENUM_MARITAL_STATUS - END */

/*
		ENUM_HTTP_STATUS - START
		Description : List of HTTP response code this for the history and response to our service.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.				    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_HTTP_STATUS
	{
		/* INFORMATIONAL - START*/

		Continue = 100,
		SwitchingProtocol = 101,
		Processing = 102,

		/* INFORMATIONAL - END */

		/* SUCCESS - START */

		Ok = 200,
		Created = 201,
		Accepted = 202,
		NonAuthorativeInformation = 203,
		NoContent = 204,
		ResetContent = 205,
		PartialContent = 206,
		MultiStatus = 207,
		AlreadyReported = 208,
		IMUsed = 226,

		/* SUCCESS - END */

		/* REDIRECTION - START */

		MultipleChoices = 300,
		MovedPermanently = 301,
		Found = 302,
		SeeOther = 303,
		NotModified = 304,
		UseProxy = 305,
		Unused = 306,
		TemporaryRedirect = 307,
		PermanentRedirection = 308,

		/* REDIRECTION - END */

		/* CLIENT ERROR - START */

		BadRequest = 400,
		Unauthorized = 401,
		PaymentRequired = 402,
		Forbidden = 403,
		NotFound = 404,
		MethodNotAllowed = 405,
		NotAcceptable = 406,
		ProxyAuthenticationRequired = 407,
		RequestTimeout = 408,
		Conflict = 409,
		Gone = 410,
		LengthRequired = 411,
		PreconditionFailed = 412,
		RequestEntityTooLarge = 413,
		RequestURITooLong = 414,
		UnsupportedMediaType = 415,
		RequestedRangeNotSatisfiable = 416,
		ExpectationFailed = 417,
		ImATeapot = 418,
		EnhanceYourCalm = 420,
		UnprocessableEntity = 422,
		Locked = 423,
		FailedDependency = 424,
		ReservedForWebDAV = 425,
		UpgradeRequired = 426,
		PreconditionRequires = 428,
		TooManyRequest = 429,
		RequestHeaderFieldsTooLarge = 431,
		NoResponse = 444,
		RetryWith = 449,
		BlockedByWindowsParentalControls = 450,
		UnavailableForLegalReasons = 451,
		ClientClosedRequest = 499,
		BlockedByCORS = 0,

		/* CLIENT ERROR - END */

		/* SERVER ERROR - START */

		InternalServerError = 500,
		NotImplemented = 501,
		BadGateway = 502,
		ServiceUnavailable = 503,
		GatewayTimeout = 504,
		HTTPVersionNotSupported = 501,
		VariantAlsoNegotiates = 506,
		InsufficientStorage = 507,
		LoopDetected = 508,
		BandwithLimitExceeded = 509,
		NotExtended = 510,
		NetworkAuthenticationRequired = 511,
		NetworkReadTimeoutError = 598,
		NetworkConnectTimeoutError = 599

		/* SERVER ERROR - END */
	}

/* ENUM_HTTP_STATUS - END */

/*
		ENUM_SURVEY_STATUS - START
		Description : Set of constants for survey status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SURVEY_STATUS
	{
		Pending = 0,
		Approved = 1,
		Rejected = 2,
	}

/* ENUM_SURVEY_STATUS - END */

/*
		ENUM_UNDERWRITING_STATUS - START
		Description : Set of constants for underwriting status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_UNDERWRITING_STATUS
	{
		Pending = 0,
		Approved = 1,
		Rejected = 2,
	}

/* ENUM_UNDERWRITING_STATUS - END */

/*
		ENUM_SPPA_STATUS - START
		Description : Set of constants for sppa status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_SPPA_STATUS
	{
		OnProgress = 0,
		ReadyForSave = 1,
		Saved = 2,
		Approved = 3,
		PendingForSurvey = 4,
		RejectedSurvey = 5,
		PendingForUnderwriting = 6,
		RejectedUnderwritting = 7,
		PendingForPayment = 8,
		Paid = 9,
		Completed = 10
	}

/* ENUM_SPPA_STATUS - END */

/*
		ENUM_POLICY_STATUS - START
		Description : Set of constants for policy status.
		Author : Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.						    Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_POLICY_STATUS
	{
		Active = 1,
		Outstanding = 2,
		Inactive = 3,
		Expired = 4,
		Terminated = 5,
		Canceled = 6,
		Pending = 7
	}

/* POLICY_STATUS - END */

//#endregion


//#region STATUS

/*
	ENUM_DOCUMENTSTAMPCOLLECTORAPPROVAL_STATUS - START
	Description : Set of document stamp collector approval status
	Created by : Andri Septiawan.										Created by : Friday, 14 April 2023.
	Updated by : Andri Septiawan.										Updated by : Friday, 14 April 2023.
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTORAPPROVAL_STATUS
{
	SubmittedByMaker = 0,
	RejectedByChecker = 1,
	ApprovedByChecker = 2,
	RejectedBySigner = 3,
	ApprovedBySigner = 4,
}

/* ENUM_DOCUMENTSTAMPCOLLECTORAPPROVAL_STATUS - END */


/*
	ENUM_DOCUMENTSTAMPCOLLECTOR_OVERALLSTATUS - START
	Description : Set of document stamp collector overall status
	Created by : Andri Septiawan.										Created by : Friday, 14 April 2023.
	Updated by : Andri Septiawan.										Updated by : Friday, 14 April 2023.
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTOR_OVERALLSTATUS
{
	SubmittedByMaker = 0,
	RejectedByChecker = 1,
	ApprovedByChecker = 2,
	RejectedBySigner = 3,
	ApprovedBySigner = 4,
	StampingInProgress = 5,
	StampingSuccess = 6,
}

/* ENUM_DOCUMENTSTAMPCOLLECTOR_OVERALLSTATUS - END */

//#endregion


//#region MISELANIOUS

/*
		ENUM_FONT_FAMILY - START
		Description : This will be tight to user model, to set up the font family preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_FONT_FAMILY
	{
		Primary = 0,
		Second = 1
	}

/* ENUM_FONT_FAMILY - END */

/*
		ENUM_FONT_SIZE - START
		Description : This will be tight to user model, to set up the font size preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_FONT_SIZE
	{
		Small = 0,
		Medium = 1,
		Large = 2
	}

/* ENUM_FONT_SIZE - END */

/*
		ENUM_THEME_COLOUR - START
		Description : This will be tight to user model, to set up the theme color preference.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_THEME_COLOUR
	{
		Fioralba = 0,
		Aurora = 1
	}

/* ENUM_THEME_COLOUR - END */

/*
		ENUM_DATE_MONTH - START
		Description : This will be used for spinner date.
		Author: Andri Septiawan.
		Created on : Monday, 28 December 2020.          Updated on : Monday, 28 December 2020.
		Created by : Andri Septiawan.					Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

export enum ENUM_DATE_MONTH
	{
		January = 1,
		February = 2,
		March = 3,
		April = 4,
		May = 5,
		June = 6,
		July = 7,
		August = 8,
		September = 9,
		October = 10,
		November = 11,
		December = 12
	}

/* ENUM_DATE_MONTH - END */

/*
		ENUM_LETTER_STATUS - START
		Description : This role user in kartini website.
		Author: Billy Johannes.
		Created on : Friday, 19 March 2021.        		Updated on : Friday, 16 April 2021..
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.1:1.
	*/

export enum ENUM_LETTER_STATUS
{
	OnProgress = 0,
	PendingForCheck = 1,
	PendingForSign = 2,
	Rejected = 3,
	Signed = 4,
	Printed = 5,
	Sent = 6,
	ApprovedByAKU = 7,
	PendingForMakerAKU = 8,
	PendingForCheckerAKU = 9,
	PendingForSignerAKU = 10,
	PendingForPaid = 11,
	Paid = 12,
	Failed = 13,
	SuccessfullyPaid = 14,
	VoucherCreated = 15,
}

/* ENUM_LETTER_STATUS - END */

/*
		ENUM_LETTER_APPROVAL - START
		Description : This role user in kartini website.
		Author: Billy Johannes.
		Created on : Tuesday, 04 May 2021.        	Updated on : Tuesday, 04 May 2021.
		Created by : Billy Johannes.				Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_LETTER_APPROVAL
	{
		Pending = 1,
		Approve = 2,
		Reject = 3,
	}

/*  ENUM_LETTER_APPROVAL - END */

/*
		ENUM_LETTERROLE_TYPE - START
		Description : For categorized and role user in kartini website.
		Author: Billy Johannes.
		Created on : Friday, 16 April 2021.        		Updated on : Friday, 16 April 2021.
		Created by : Billy Johannes.					Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_LETTER_ROLE
	{
		Maker = 0,
		Checker = 1,
		Signer = 2
	}

/* ENUM_LETTERROLE_TYPE - END */

/*
	ENUM_DIVISION_ID - START
	Description : This division id used accross website to determine division validation process.
	Author: Billy Johannes.
	Created on : Friday, 28 May 2021.        		Updated on : Friday, 28 May 2021.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_DIVISION_ID
{
	NoDivision = 0,
	OperationAndSupport = 15,
}

/* ENUM_DIVISION_ID - END */

/*
	ENUM_TICKET_STATUS - START
	Description : Set of constants for policy status.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.           Updated on : Monday, 28 December 2020.
	Created by : Ibrahim Aziz.      Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

export enum ENUM_TICKET_STATUS
{
	Pending = 0,
	InProgress = 1,
	ClosedWithConfirmation = 2,
	ClosedWithoutConfirmation = 3,
	NotClosedWithConfirmation = 4,
	NotClosedWithoutConfirmation = 5,
	Canceled = 6,
	WaitingForResponse = 7
}

/* ENUM_TICKET_STATUS - END */

/*
	ENUM_TICKET_TOPIC_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Egar Prasetya.
	Created on : Monday, 9 January 2023.          	Updated on : -
	Created by : Rizqi Alfiyati.				Updated by : -
	Version : 1.5:1.
*/

export enum ENUM_TICKET_TOPIC_TYPE
{
	Information = 0,
	Claim = 1,
	Complaint = 2,
	BRI = 3,
	Others = 4,
	Renewal = 5
}

/* ENUM_TICKET_TOPIC_TYPE - END */

/*
		ENUM_PAYMENT_TYPE - START
		Description : For payment type in BRINESIA environment.
		Author: Billy Johannes.
		Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_PAYMENT_TYPE
	{
		Cash = 0,
		Transfer = 1
	}

/* ENUM_PAYMENT_TYPE - END */

/*
		ENUM_PAYMENTPRIORITY_TYPE - START
		Description : For payment priority type in BRINESIA environment.
		Author: Billy Johannes.
		Created on : Tuesday, 23 March 2021.        		Updated on : Tuesday, 23 March 2021.
		Created by : Billy Johannes.						Updated by : Billy Johannes.
		Version : 1.0:0.
	*/

export enum ENUM_PAYMENTPRIORITY_TYPE
	{
		Normal = 0,
		Important = 1,
		VeryImportant = 2
	}

/* ENUM_PAYMENTPRIORITY_TYPE - END */

/*
    ENUM_PLATFORM_TYPE - START
    Description : Set of constants for platform type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_PLATFORM_TYPE
	{
		Windows = 0,
		Mac = 1,
		Linux = 2,
		Android = 3,
		IOS = 4
	}

/* ENUM_PLATFORM_TYPE - END */

/*
    ENUM_DEVICE_TYPE - START
    Description : Set of constants for device type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Friday, 15 January 2021.   Updated on : Friday, 15 January 2021.
    Created by : Andri Septiawan.          	Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_DEVICE_TYPE
	{
		Desktop = 0,
		Website = 1,
		Handphone = 2,
		Tablet = 3
	}

/* ENUM_DEVICE_TYPE - END */

/*
    ENUM_SORT_TYPE - START
    Description : Set of constants for device type for analytics and issue tracking.
    Author : Andri Septiawan.
    Created on : Thursday, 18 February 2021.   	Updated on : Thursday, 18 February 2021.
    Created by : Andri Septiawan.          		Updated by : Andri Septiawan.
    Version : 1.0:1.
	*/

export enum ENUM_SORT_TYPE
	{
		Ascending = 0,
		Descending = 1,
	}

/* ENUM_SORT_TYPE - END */


/*
	ENUM_REALIZATION_TYPE - START
	Description : Set of constants for realization sub type for parameter.
	Author : Billy Johannes.
	Created on : Tuesday, 20 June 2023.        Updated on : Tuesday, 20 June 2023.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_REALIZATION_TYPE
{
	Other = 0,
	Parameter = 1,
	SubParameter = 2,
	SubSubParameter = 3,
}

/* ENUM_REALIZATION_TYPE - END */


/*
	ENUM_TOPIC_TYPE - START
	Description : Set of constants for ticket topic type for ticket.
	Author : Billy Johannes.
	Created on : Monday, 24 May 2021.          	Updated on : Monday, 24 May 2021.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_TOPIC_TYPE
{
	SignIn = 0,
	PremiumCalculation = 1,
	Payment = 2,
	Claim = 3,
	Policy = 4,
	ProductInsurance = 5,
	BRINSSMART = 6,
	Other = 7
}

/* ENUM_TOPIC_TYPE - END */

/*
	ENUM_SEVERITY_TYPE - START
	Description : Set of constants for severity status for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_SEVERITY_TYPE
{
	Low = 0,
	Medium = 1,
	High = 2,
	Urgent = 3
}

/* ENUM_SEVERITY_TYPE - END */

/*
	ENUM_TICKETCHAT_TYPE - START
	Description : Set of constants for ticket chat type for ticket.
	Author : Ibrahim Aziz.
	Created on : Tuesday, 13 April 2021.          	Updated on : Tuesday, 13 April 2021.
	Created by : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

export enum ENUM_TICKETCHAT_TYPE
{
	InternalBRINS = 0,
	General = 1
}

/* ENUM_TICKETCHAT_TYPE - END */

//#endregion

/*
	ENUM_DOCUMENTSTAMPCOLLECTOR_TYPE - START
	Description : This is used for stamp collector document type
	Author: Andryana Baros.
	Created on : Monday, 07 March 2022.        		Updated on : -.
	Created by : Andryana Baros.					Updated by : -.
	Version : 1.1:32.
*/

export enum ENUM_DOCUMENTSTAMPCOLLECTOR_TYPE
{
	Security = 0,
	TransactionDocument = 1,
	MiscellaneousDocument = 2,
	MikAmountOfMoneyMoreThanFiveMillionStatementDocumentro = 3,
}

/* ENUM_DOCUMENTSTAMPCOLLECTOR_TYPE - END */

/*
	ENUM_PROJECTCODE - START
	Description : This is used for Project Code
	Author: Andri Septiawan.
	Created on : Sunday, 17 July 2022.        		Updated on : Sunday, 17 July 2022.
	Created by : Andri Septiawan.					Updated by : Andri Septiawan.
	Version : 1.0:0.
*/

export enum ENUM_PROJECT_CODE
{
	Anjani = 1,
	Bromo = 2,
	Citrasena = 3,
	Dharmawangsa = 4,
	Eboni = 5,
	Foja = 6,
	Gili = 7,
	Halimun = 8,
	Ismaya = 9,
	Joho = 10,
	Kartini = 11,
	Legian = 12,
	Maninjau = 13,
	Natuna = 14,
	Opak = 15,
	Patma = 16,
	Quartz = 17,
	Rinjani = 18,
	Sentani = 19,
	Trijata = 20,
	Ungaran = 21,
	VanDaalen = 22,
	Widura = 23,
	Xanthite = 24,
	Yaro = 25,
	Zircon = 26,
	Arjuna = 27,
	Bima = 28,
	Ciremai = 29,
	Diponegoro = 30,
	Ekalawya = 31,
	Fatmawati = 32,
	Gamalama = 33,
	Halmahera = 34,
	Intata = 35,
	Jayawijaya = 36,
	Karimun = 37,
	Laksmana = 38,
}

/* ENUM_PROJECTCODE - END */


/*
	ENUM_USERGROUPID - START
	Description : This is used for user group id
	Author: Eka Saputra.
	Created on : tuesday, 13 September 2022.        		Updated on : -.
	Created by : Eka Saputra.								Updated by : -.
	Version : 1.0:0.
*/

export enum ENUM_USERGROUPID
{
	Administrator = 1,
	RelationshipManager = 2,
	LogisticMaker = 3,
	LogisticChecker = 4,
	LogisticSigner = 5,
	LogisticCheckerSigner = 6,
	JLO = 7,
	AKUCheckerSigner = 8,
	Bisnis = 9,
	ThirdParty = 10,
	Customer = 11,
	BranchSyariah = 12,
	JamkrindoSyariah = 13,
	ClaimMaker = 14,
	ClaimChecker = 15,
	ClaimCheckerSigner = 16,
	ClaimSigner = 17,
	SyariahMaker = 18,
	SyariahChecker = 19,
	SyariahSigner = 20,
	SyariahCheckerSigner = 21,
	Director = 22,
	KPRSBRI = 23,
	InternalAuditMember = 24,
	HumanResource = 25,
	CompanySecretariatMaker = 26,
	ComplianceAndRiskManagement = 27,
	Legal = 28,
	StrategicAndPlanning = 29,
	Actuarial = 30,
	PolicyAndBusinessDevelopment = 31,
	TreatyAndPortfolioAnalysis = 32,
	UnderwriterAndReassurance = 33,
	BranchManager = 34,
	Surveyor = 35,
	DigitalMicroAndAgency = 36,
	Employee = 37,
	AKUMaker = 38,
	AKUChecker = 39,
	AKUSigner = 40,
	BranchBusiness = 41,
	BranchAdmin = 42,
	TechnologyAndInformationSystemMaker = 43,
	TechnologyAndInformationSystemChecker = 44,
	TechnologyAndInformationSystemSigner = 45,
	TechnologyAndInformationSystemCheckerSigner = 46,
	BSI = 47,
	BranchSyariahAsmik = 48,
	BranchSyariahAsmikJamsyar = 49,
	BranchSyariahAccounting = 50,
	DeputyBranchManager = 51,
	ClaimNonMotorvehicleMaker = 52,
	InternalAuditApprovalMaker = 53,
	InternalAuditApproval = 54,
	InternalAuditMaker = 55,
	InternalAuditHead = 56,
	ClaimNonMotorvehicleChecker = 57,
	ClaimNonMotorvehicleSigner = 58,
	ClaimNonMotorvehicleCheckerSigner = 59,
	RiskChampion = 60,
	RiskApprover = 61,
	ComplienceAndRiskManagementMaker = 62,
	CompanySecretariatChecker = 76,
	CompanySecretariatSigner = 77,
	CompanySecretariatCheckerSigner = 78,
	GCGAdmin = 110,
	GCGChecker = 111,
	GCGSigner = 112
}

/* ENUM_USERGROUPID - END */

/*
	ENUM_GRATIFICATION_STATUS - START
	Description : Set of constants for status GCG Gratification			Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_GRATIFICATION_STATUS
{
	Created = 0,
	InReview = 1,
	Reviewed = 2,
}

/* ENUM_GRATIFICATION_STATUS - END */

/*
	ENUM_CODEOFETHICS_STATUS - START
	Description : Set of constants for status GCG Code Of Ethics		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_CODEOFETHICS_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_CODEOFETHICS_STATUS - END */

/*
	ENUM_CODEOFETHICSAPPROVAL_STATUS - START
	Description : Set of constants for status GCG Code Of Ethics		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_CODEOFETHICSAPPROVAL_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_CODEOFETHICSAPPROVAL_STATUS - END */

/*
	ENUM_ANNUALDISCLOSURE_STATUS - START
	Description : Set of constants for status GCG Annual Disclosure		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_ANNUALDISCLOSURE_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_ANNUALDISCLOSURE_STATUS - END */

/*
	ENUM_ANNUALDISCLOSUREAPPROVAL_STATUS - START
	Description : Set of constants for status GCG Annual Disclosure		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_ANNUALDISCLOSUREAPPROVAL_STATUS
{
	SubmittedByEmployee = 0,
	RejectedByGCGChecker = 1,
	ApprovedByGCGChecker = 2,
	RejectedByGCGSigner = 3,
	ApprovedByGCGSigner = 4,
}

/* ENUM_ANNUALDISCLOSUREAPPROVAL_STATUS - END */

/*
	ENUM_ANNUALDISCLOSUREQUESTION_ANSWER - START
	Description : Set of constants for status GCG Annual Disclosure		Updated on : .
	Created by : Billy Johannes.										Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_ANNUALDISCLOSUREQUESTION_ANSWER
{
	Yes = 0,
	No = 1
}

/* ENUM_ANNUALDISCLOSUREQUESTION_ANSWER - END */

/*
		ENUM_REIMBURSEMENT_TYPE - START
		Description : Set of constants for type reimbursement.
		Author : Andryana Baros.
		Created on : Friday, 05 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_REIMBURSEMENT_TYPE
	{
		DentalReimbursement = 1,
		OpticReimbursement = 2,
		PreMaternityReimbursement = 3,
		MaternityReimbursement = 4,
		PostMaternityReimbursement = 5,
		PaidLeaveReimbursement = 6
	}

/* ENUM_REIMBURSEMENT_TYPE - END */


/*
		ENUM_REIMBURSEMENT_STATUS - START
		Description : Set of constants for status reimbursement.
		Author : Andryana Baros.
		Created on : Friday, 05 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_REIMBURSEMENT_STATUS
	{
		OnProgress = 0,
		PendingForCheck = 1,
		PendingForSign = 2,
		Rejected = 3,
		Sign = 4,
		Approved = 5
	}

/* ENUM_REIMBURSEMENT_STATUS - END */


/*
		ENUM_COVERED_PERSON - START
		Description : Set of constants for status covered reimbursement.
		Author : Andryana Baros.
		Created on : Friday, 05 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.0:1.
	*/

export enum ENUM_COVERED_PERSON
	{
		Self = 0,
		Husband = 1,
		Wife = 2,
		FirstChild = 3,
		SecondChild = 4,
		ThirdChild = 5
	}

/* ENUM_CHILD_NUMBER - END */


/*
		ENUM_CHILD_NUMBER - START
		Description : Set of constants for child number.
		Author : Andryana Baros.
		Created on : Monday, 22 November 2021.			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_CHILD_NUMBER
	{
		FirstChild = 0,
		SecondChild = 1,
		ThirdChild = 2
	}

/* ENUM_CHILD_NUMBER - END */


/*
		ENUM_USG_TYPE - START
		Description : Set of constants for USG Type			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_USG_TYPE
	{
		TwoDimension = 0,
		ThreeDimension = 1
	}

/* ENUM_USG_TYPE - END */


/*
		ENUM_MATERNITY_TYPE - START
		Description : Set of constants for Maternity Type			Updated on : .
		Created by : Andryana Baros.						Updated by : .
		Version : 1.1:2.
	*/

export enum ENUM_MATERNITY_TYPE
	{
		Normal = 0,
		Caesarean = 1,
		Premature = 2
	}

/* ENUM_MATERNITY_TYPE - END */

//#endregion

/*
	ENUM_RAS_LIMITTYPE - START
	Description : Set of constants for RAS Operator Type	Updated on : .
	Created by : Billy Johannes.							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RAS_LIMITTYPE
{
	Internal = 0,
	Industry = 1,
}

/* ENUM_RAS_LIMITTYPE - END */

/*
	ENUM_RAS_REPORTTYPE - START
	Description : Set of constants for RAS Operator Type	Updated on : .
	Created by : Billy Johannes.							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RAS_REPORTTYPE
{
	Bulletin = 0,
	Annual = 1,
}

/* ENUM_RAS_REPORTTYPE - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETER_UNIT - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETER_UNIT
{
	Percentage = 0,
	Value = 1,
}


/* ENUM_RISKAPPETITESTATEMENTPARAMETER_UNIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBPARAMETER_UNIT - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBPARAMETER_UNIT
{
	Percentage = 0,
	Value = 1,
}


/* ENUM_RISKAPPETITESTATEMENTSUBPARAMETER_UNIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETER_UNIT - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETER_UNIT
{
	Percentage = 0,
	Value = 1,
}


/* ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETER_UNIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTINTERNAL_ORDER - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTINTERNAL_ORDER
{
    Ascending = 0,
    Descending = 1
}

/* ENUM_RISKAPPETITESTATEMENTINTERNAL_ORDER - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_INTERNALLIMIT - START
	Description : Set of constants for RAS Internal Limit Type	Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_INTERNALLIMIT
{
	Low = 1,
	LowToModerate = 2,
	Moderate = 3,
	ModerateToHigh = 4,
	High = 5,
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_INTERNALLIMIT - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_STATUS
{
    Created = 0,
    ProvidedByDataManagement = 1,
    SubmittedByMaker = 2,
    RejectedBySigner = 3,
    ApprovedBySigner = 4,
	RejectedByConfirmer = 5,
	ApprovedByConfirmer = 6,
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATION_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2,
	RejectedByConfirmer = 3,
	ApprovedByConfirmer = 4,
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERREALIZATIONAPPROVAL_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN_STATUS
{
    Created = 0,
    SubmittedByMaker = 1,
    RejectedBySigner = 2,
    ApprovedBySigner = 3
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLAN_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2
}

/* ENUM_RISKAPPETITESTATEMENTPARAMETERFOLLOWUPPLANAPPROVAL_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION_STATUS
{
    Created = 0,
    ProvidedByDataManagement = 1,
    SubmittedByMaker = 2,
    RejectedBySigner = 3,
    ApprovedBySigner = 4,
	RejectedByConfirmer = 5,
	ApprovedByConfirmer = 6,
}

/* ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATION_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2,
	RejectedByConfirmer = 3,
	ApprovedByConfirmer = 4,
}

/* ENUM_RISKAPPETITESTATEMENTSUBPARAMETERREALIZATIONAPPROVAL_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION_STATUS
{
    Created = 0,
    ProvidedByDataManagement = 1,
    SubmittedByMaker = 2,
    RejectedBySigner = 3,
    ApprovedBySigner = 4,
	RejectedByConfirmer = 5,
	ApprovedByConfirmer = 6,
}

/* ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATION_STATUS - END */

/*
	ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL_STATUS - START
	Description : Set of constants for RAS Industry Unit		Updated on : .
	Created by : Billy Johannes.								Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL_STATUS
{
    SubmittedByMaker = 0,
    RejectedBySigner = 1,
    ApprovedBySigner = 2,
	RejectedByConfirmer = 3,
	ApprovedByConfirmer = 4,
}

/* ENUM_RISKAPPETITESTATEMENTSUBSUBPARAMETERREALIZATIONAPPROVAL_STATUS - END */

/*
	ENUM_RISKASSIGN_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 15 November 2022.        Updated on : Thursday, 15 September 2022.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKASSIGN_STATUS
{
	NotComplete = 1,
	Completed = 2,
	PendingForApproval = 3,
	ApprovedByWorkingUnit = 4,
	RejectedByWorkingUnit = 5,
	ApprovedByComplianceAndRiskManagement = 6,
	RejectedByComplianceAndRiskManagement = 7
}

/* ENUM_RISKASSIGN_STATUS - END */

/*
	ENUM_ISSUE_TYPE - START
	Description : Set of constants for issue type, to define what issue type included in.
	Author : Billy Johannes.
	Created on : Wednesday, 15 November 2022.      Updated on : Wednesday, 14 September 2022.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_ISSUE_TYPE
{
	Mandatory = 1,
	Optional = 2
}

/* ENUM_ISSUE_TYPE - END */

/*
	ENUM_RISKASSIGNCONTENT_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Monday, 15 November 2022.			Updated on : Monday, 26 September 2022.
	Created by : Billy Johannes.				Updated by : Billy Johannes.
	Version : 1.0:0.
*/

export enum ENUM_RISKASSIGNCONTENT_STATUS
{
	NotComplete = 1,
	Completed = 2,
}

/*ENUM_RISKASSIGNCONTENT_STATUS - END */

/*
	ENUM_RISKISSUE_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 1 September 2022.        Updated on : Thursday, 1 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKISSUE_STATUS
{
	All = 0,
	Active = 1,
	Inactive = 2
}

/* ENUM_RISKISSUE_STATUS - END */

/*
	ENUM_RISKACTION_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 15 November 2022.        Updated on : Thursday, 1 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKACTION_STATUS
{
	Active = 0,
	Inactive = 1
}

/* ENUM_RISKACTION_STATUS - END */

/*
	ENUM_RISKACTIVITY_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 1 September 2022.        Updated on : Thursday, 1 September 2022.
	Created by : Billy Johannes.					Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKACTIVITY_STATUS
{
	All = 0,
	Active = 1,
	Inactive = 2
}

/* ENUM_RISKACTIVITY_STATUS - END */

/*
	ENUM_RISKCONTROL_STATUS - START
	Description : Set of constants for state general.
	Author : Billy Johannes.
	Created on : Thursday, 15 November 2022.        Updated on : Thursday, 1 September 2022.
	Created by : Billy Johannes.			Updated by : Billy Johannes.
	Version : 1.0:1.
*/

export enum ENUM_RISKCONTROL_STATUS
{
	All = 0,
	Active = 1,
	Inactive = 2
}

/* ENUM_RISKCONTROL_STATUS - END */

/*
	ENUM_RISKCONTROL_STATUS - START
	Description : Set of constants for state general.
	Author : Rizqi.
	Created on : Monday, 05 Desember 2022.        Updated on :
	Created by : Rizqi.			Updated by :
	Version : 1.0.0.
*/

export enum ENUM_RISKREQUEST_STATUS
{
	All = 0,
	Draft = 1,
	Sent = 2,
}


/*
	ENUM_DOCUMENT_STATUS - START
	Description : Set of constants for status document					Updated on : .
	Created by : Abdullah Fahmi.										Updated by : .
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENT_STATUS
{
	Draft = 0,
	Revise = 1,
	PendingCheckerDivision = 2,
	PendingSignerDivision = 3,
	PendingConfirmation = 4,
	PendingMaker = 5,
	PendingChecker = 6,
	PendingSigner = 7,
	Rejected = 8,
	Approved = 9
}

/* ENUM_DOCUMENT_STATUS - END */


/*
	ENUM_DOCUMENT_CATEGORY - START
	Description : Set of constants for document category 		Updated on : .
	Created by : Abdullah Fahmi.								Updated by : .
	Version : 1.1:5.
*/

export enum ENUM_DOCUMENT_CATEGORY
{
	CompanyDeed = 0,
	Others = 1
}

/* ENUM_DOCUMENT_CATEGORY - END */


/*
	ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - START
	Description : This is used for satisfaction ticket
	Author : Yozi Maulana
	Created on : Monday, 12 February 2024.					Updated on : Monday, 12 February 2024.
	Created by : Eka Saputra								Updated by : Eka Saputra
	Version : 1.0:0.
*/

export enum ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE
{
	VeryDissatisfied = 0,
	NotSatisfied= 1,
	Satisfied= 2,
	VerySatisfied= 3,
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOKARTICLE_STATUS - START
	Description : This is used for outlook article status
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOKARTICLE_STATUS
{
	New = 0,
    Accepted = 1,
    Archived = 2
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOK_STATUS - START
	Description : This is used for outlook status
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOK_STATUS
{
	Published = 0,
    Archived = 1
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOKARTICLE_AUTHORWORKINGUNITTYPE - START
	Description : This is used for outlook article authord working unit type
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOKARTICLE_AUTHORWORKINGUNITTYPE
{
	Headquarters = 0,
    Branch = 1
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */


/*
	ENUM_OUTLOOKGALLERY_TYPE - START
	Description : This is used for outlook gallery type
	Author : Dimas Dandy
	Created on : Monday, 25 March 2024.					Updated on : .
	Created by : Dimas Dandy							Updated by : .
	Version : 1.0:0.
*/

export enum ENUM_OUTLOOKGALLERY_TYPE
{
	Cover = 0,
    Attachment = 1
}

/* ENUM_CUSTOMERSATISFACTION_ASSESSMENTRATE - END */

/*
	ENUM_BRINSUPDATE_PRIORITY - START
	Description : Set of constants for type of priority BRINS Update .
	Author : Eka Saputra.
	Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
	Created by : Eka Saputra.							Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_BRINSUPDATE_PRIORITY
{
	Normal = 0,
	Highlight = 1,
}

/* ENUM_BRINSUPDATE_PRIORITY - END */

/*
		ENUM_BRINSUPDATE_TYPE - START
		Description : Set of constants for type of BRINS Update .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRINSUPDATE_TYPE
{
	Award = 0,
	Community = 1,
	Discount = 2,
	FinancialLiteracy = 3,
	NewsFeed = 4,
	Testimony = 5,
}

/* ENUM_BRINSUPDATE_TYPE - END */

/*
		ENUM_BRINSUPDATE_CATEGORY - START
		Description : Set of constants for category of BRINS Update .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRINSUPDATE_CATEGORY
{
	Award = 0,
	Community = 1,
	Discount = 2,
	FinancialLiteracy = 3,
	NewsFeed = 4,
	Testimony = 5,
}

/* ENUM_BRINSUPDATE_CATEGORY - END */

/*
		ENUM_BRINSUPDATE_PUBLISHEDSTATUS - START
		Description : Set of constants for category of BRINS Update .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_BRINSUPDATE_PUBLISHEDSTATUS
{
	SubmittedByMaker = 0,
	RejectedBySigner = 1,
	ApprovedBySigner = 2,
}

/* ENUM_BRINSUPDATE_PUBLISHEDSTATUS - END */

/*
	ENUM_AWARD_TYPE - START
	Description : Set of constants for type of Award .
	Author : Eka Saputra.
	Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
	Created by : Eka Saputra.							Updated by : Eka Saputra.
	Version : 1.0:1.
*/

export enum ENUM_AWARD_TYPE
{
	BestAward = 0,
	Award = 1,
}

/* ENUM_SUBMISSIONCLAIMNONMV_TYPE - END */

/*
		ENUM_NEWSFEED_PRIORITY - START
		Description : Set of constants for type of priority news feeds .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_NEWSFEED_PRIORITY
{
	NotShow = 0,
	Show = 1,
	Priority = 2,
}

/* ENUM_NEWSFEED_PRIORITY - END */

/*
		ENUM_AWARD_PRIORITY - START
		Description : Set of constants for type of priority award .
		Author : Eka Saputra.
		Created on : Tuesday, 29 March 2022.				Updated on : Tuesday, 29 March 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

export enum ENUM_AWARD_PRIORITY
{
	Normal = 0,
	Best = 1,
	VeryBest = 2
}

/* ENUM_AWARD_PRIORITY - END */

//#endregion