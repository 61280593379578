//#region IMPORT

import { BaseModel } from "./bases/base.model";
import { RiskRequestModel } from "./riskrequest.model";

//#endregion


//#region CLASS

export class DivisionModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for division model, used for link to formation and position.
		Author: Ibrahim Aziz.
		Created on : Tuesday, 30 March 2021. 				Updated on : Tuesday, 30 March 2021.
		Created by : Ibrahim Aziz.							Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	Code?: string;
	Name?: string;
	Token?: string;

	listModelRiskRequest?: Array<RiskRequestModel>;

	/* Attribute - END */
}

//#endregion