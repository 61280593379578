//#region IMPORT

import { AccountChargeModel } from "./accountcharge.model";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class AccountChargeDivisionModel extends BaseModel
{
	ID?: number;
	DivisionID?: string;
	AccountChargeID?: string;

	modelAccountCharge?: AccountChargeModel;

	//#region CONSTRUCTOR

	constructor()
	{
		super();
		this.modelAccountCharge = new AccountChargeModel();
	}

	//#endregion


	//#region CLEAR

	clearForTokenOnly(): void
	{
		this.cleanAllFootprint();
		this.modelAccountCharge = undefined;
	}

	//#endregion
}

//#endregion