//#region IMPORT

import { ENUM_RESPONSE_STATE, ENUM_LETTER_TYPE } from "../constants/enum.constant";
import { AccountChargeModel } from "./accountcharge.model";
import { BaseModel } from "./bases/base.model";
import { CurrencyModel } from "./currency.model";
import { ResponseModel } from "./response.model";
import { DeclarationDetailModel } from "./declarationdetail.model";
import { BranchModel } from "./branch.model";

//#endregion


//#region CLASS

export class DeclarationVideModel extends BaseModel
{
	ID?: number;
	DeclarationDetailID?: number;
	AccountChargeID?: number;
	Name?: string;
	Description?: string;
	Amount?: number;
	RealizationAmount?: number;
	SelisihAmount?: number;
	CurrencyID?: number;
	BranchCodeResponsibility?: string;
	BranchNameResponsibility?: string;
	modelDeclarationDetail?: DeclarationDetailModel;
	modelAccountCharge?: AccountChargeModel;
	modelCurrency?: CurrencyModel;
	modelBranch?: BranchModel;

	LetterType?: ENUM_LETTER_TYPE;

	constructor()
	{
		super();
		this.modelCurrency = new CurrencyModel();

		if(this.Amount !== undefined && this.RealizationAmount !== undefined)
		{
			this.SelisihAmount = this.Amount - this.RealizationAmount;
		}
		else
		{

		}
	}
	//#region VALIDATION

	validateInsert(enumLetterType: ENUM_LETTER_TYPE): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration vide");

		this.LetterType = enumLetterType;

		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama vide tidak boleh kosong!";
		}
		else if (this.AccountChargeID == null || this.AccountChargeID === undefined)
		{
			modelResponse.MessageContent = "Biaya akun tidak boleh kosong!";
		}
		else if (this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment || this.LetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt)
		{
			if (this.RealizationAmount == null || this.RealizationAmount === undefined || this.RealizationAmount <= 0)
			{
				modelResponse.MessageContent = "Nominal realisasi tidak boleh kosong!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.LetterType === ENUM_LETTER_TYPE.DeclarationPayment || this.LetterType === ENUM_LETTER_TYPE.DeclarationReimburse)
		{
			if (this.Amount === 0 || this.Amount == null || this.Amount === undefined)
			{
				modelResponse.MessageContent = "Nominal pengajuan tidak boleh kosong!";
			}
			else if (this.CurrencyID == null || this.CurrencyID === undefined)
			{
				modelResponse.MessageContent = "Mata uang tidak boleh kosong!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelAccountCharge(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration vide");

		if (this.modelAccountCharge == null || this.modelAccountCharge === undefined)
		{
			modelResponse.MessageContent = "Beban akun tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelCurrency(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration vide");

		if (this.modelCurrency == null || this.modelCurrency === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada model currency, silahkan hubungi pengembang!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration vide");

		if (this.modelBranch == null || this.modelBranch === undefined)
		{
			modelResponse.MessageContent = "Beban tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	//#endregion
}

//#endregion