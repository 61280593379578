//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { AccountChargeModel } from "./accountcharge.model";
import { BaseModel } from "./bases/base.model";
import { CurrencyModel } from "./currency.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class SubmissionVideModel extends BaseModel
{
	ID?: number;
	SubmissionDetailID?: number;
	AccountChargeID?: number;
	Name?: string;
	Description?: string;
	Amount?: number;
	CurrencyID?: number;
	modelAccountCharge?: AccountChargeModel;
	modelCurrency?: CurrencyModel;

	constructor()
	{
		super();
		this.modelCurrency = new CurrencyModel();
	}
	//#region VALIDATION

	validateInsert(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission vide");

		if (this.Name == null || this.Name === undefined || this.Name === "")
		{
			modelResponse.MessageContent = "Nama vide tidak boleh kosong!";
		}
		else if (this.Amount === 0 || this.Amount == null || this.Amount === undefined)
		{
			modelResponse.MessageContent = "Nominal tidak boleh kosong!";
		}
		else if (this.AccountChargeID == null || this.AccountChargeID === undefined)
		{
			modelResponse.MessageContent = "Biaya akun tidak boleh kosong!";
		}
		else if (this.Amount < 0)
		{
			modelResponse.MessageContent = "Nominal harus lebih besar sama dengan 0.";
		}
		else if (this.CurrencyID == null || this.CurrencyID === undefined)
		{
			modelResponse.MessageContent = "Mata uang tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelAccountCharge(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission vide");

		if (this.modelAccountCharge == null || this.modelAccountCharge === undefined)
		{
			modelResponse.MessageContent = "Beban akun tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelCurrency(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission vide");

		if (this.modelCurrency == null || this.modelCurrency === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada model currency, silahkan hubungi pengembang!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion