//#region IMPORT

import { ENUM_LETTER_STATUS, ENUM_PAYMENT_TYPE, ENUM_LETTER_TYPE, ENUM_PAYMENTPRIORITY_TYPE, ENUM_RESPONSE_STATE, ENUM_BRANCH_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { DeclarationModel } from "./declaration.model";
import { DeclarationVideModel } from "./declarationvide.model";
import { ResponseModel } from "./response.model";
import { SubmissionModel } from "./submission.model";
import { SubmissionVideModel } from "./submissionvide.model";
import { UserModel } from "./user.model";
import { DeclarationDetailModel } from "./declarationdetail.model";

//#endregion


//#region CLASS

export class LetterGeneralModel extends BaseModel
{
	ID?: number;
	Status?: ENUM_LETTER_STATUS;
	Type?: ENUM_LETTER_TYPE;
	PaymentPriority?: ENUM_PAYMENTPRIORITY_TYPE;
	PaymentType?: ENUM_PAYMENT_TYPE;
	BranchType?: ENUM_BRANCH_TYPE;
	BudgetType?: string;
	BudgetYear?: number;
	TotalAmount?: number;
	DepositToDesk?: number;
	DivisionID?: number;
	FormationID?: number;
	Title?: string;

	FirstCheckerBy?: UserModel;
	FirstCheckerOn?: Date;
	FirstCheckerURL?: string;
	FirstCheckerApproval?: boolean;
	SecondCheckerBy?: UserModel;
	SecondCheckerOn?: Date;
	SecondCheckerURL?: string;
	SecondCheckerApproval?: boolean;
	FirstSignerBy?: UserModel;
	FirstSignerOn?: Date;
	FirstSignerURL?: string;
	FirstSignerAprroval?: boolean;
	SecondSignerBy?: UserModel;
	SecondSignerOn?: Date;
	SecondSignerURL?: string;
	SecondSignerApproval?: boolean;
	SentTo?: number;
	SentOn?: Date;

	/* AKU ATTRIBUTE - START */

	AccountingFirstCheckedBy?: UserModel;
	AccountingFirstCheckedOn?: Date;
	AccountingFirstCheckedURL?: string;
	AccountingSecondCheckedBy?: UserModel;
	AccountingSecondCheckedOn?: Date;
	AccountingSecondCheckedURL?: string;
	AccountingFirstSignedBy?: UserModel;
	AccountingFirstSignedOn?: Date;
	AccountingFirstSignedURL?: string;

	/* AKU ATTRIBUTE - END */

	modelUserReviewer?: UserModel;
	RejectedOn?: Date;
	RejectedBy?: number;
	RejectedNote?: string;

	Data?: string;
	CreatedURL?: string;
	FileName?: string;
	FileURL?: string;
	FileFormat?: string;
	FileSize?: string;

	Token?: string;

	modelDeclaration?: DeclarationModel;
	modelDeclarationDetail?: DeclarationDetailModel;
	modelSubmission?: SubmissionModel;

	modelUserMaker?: UserModel;
	modelUserFirstChecker?: UserModel;
	modelUserSecondChecker?: UserModel;
	modelUserFirstSigner?: UserModel;
	modelUserSecondSigner?: UserModel;

	constructor()
	{
		super();
		this.modelDeclaration = new DeclarationModel();
		this.modelSubmission = new SubmissionModel();
		this.modelUserFirstChecker = new UserModel();
		this.modelUserSecondChecker = new UserModel();
		this.modelUserFirstSigner = new UserModel();
		this.modelUserSecondSigner = new UserModel();
	}


	//#region GETTER

	getLetterTypeName(): string
	{
		if (this.Type != null && this.Type !== undefined)
		{
			if (this.Type === ENUM_LETTER_TYPE.DeclarationPayment)
			{
				return "Pembayaran";
			}
			else if (this.Type === ENUM_LETTER_TYPE.DeclarationReimburse)
			{
				return "Reimburse";
			}
			else if (this.Type === ENUM_LETTER_TYPE.DeclarationRealizationDebt)
			{
				return "Realisasi Kasbon";
			}
			else if (this.Type === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment)
			{
				return "Realisasi Uang Muka";
			}
			else if (this.Type === ENUM_LETTER_TYPE.SubmissionDebt)
			{
				return "Pengajuan Kasbon";
			}
			else if (this.Type === ENUM_LETTER_TYPE.SubmissionDownPayment)
			{
				return "Pengajuan Uang Muka";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}

	}

	getPaymentStatus(): string
	{
		if (this.Status != null && this.Status !== undefined)
		{
			if (this.Status === ENUM_LETTER_STATUS.ApprovedByAKU)
			{
				return "Disetujui Finance";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForMakerAKU)
			{
				return "Dalam Antrian Maker";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForCheckerAKU)
			{
				return "Dalam Antrian Checker";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSignerAKU)
			{
				return "Dalam Antrian Signer";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForPaid)
			{
				return "Dalam Proses";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Paid)
			{
				return "Sukses";
			}
			else if (this.Status >= ENUM_LETTER_STATUS.SuccessfullyPaid)
			{
				return "Sukses Sepenuhnya";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getPaymentPriorityName(): string
	{
		if (this.PaymentPriority != null && this.PaymentPriority !== undefined)
		{
			if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.Normal)
			{
				return "Biasa";
			}
			else if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.Important)
			{
				return "Segera";
			}
			else if (this.PaymentPriority === ENUM_PAYMENTPRIORITY_TYPE.VeryImportant)
			{
				return "Sangat Segera";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getStatusName(): string
	{
		if (this.Status != null && this.Status !== undefined)
		{
			if (this.Status === ENUM_LETTER_STATUS.OnProgress)
			{
				return "Sedang Dibuat";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForCheck)
			{
				return "Menunggu Pengecekan";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSign)
			{
				return "Menunggu Disetujui";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Rejected)
			{
				return "Ditolak";
			}
			else if (this.Status >= ENUM_LETTER_STATUS.Signed)
			{
				return "Disetujui";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	getPaymentType(): string
	{
		if (this.PaymentType != null && this.PaymentType !== undefined)
		{
			if (this.PaymentType === ENUM_PAYMENT_TYPE.Transfer)
			{
				return "Transfer";
			}
			else if (this.PaymentType === ENUM_PAYMENT_TYPE.Cash)
			{
				return "Cash";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getProgress(): number
	{
		let numberProgress: number = 0;

		if(this.Status === ENUM_LETTER_STATUS.Rejected || this.Status === ENUM_LETTER_STATUS.OnProgress || this.Status === ENUM_LETTER_STATUS.PendingForCheck)
		{
			numberProgress = 0;
		}
		else if(this.Status === ENUM_LETTER_STATUS.PendingForSign && this.FirstSignerOn !== undefined)
		{
			numberProgress = 2;
		}
		else if(this.Status === ENUM_LETTER_STATUS.PendingForSign && this.FirstCheckerOn !== undefined)
		{
			numberProgress = 1;
		}
		else if(this.Status !== undefined && this.Status >= ENUM_LETTER_STATUS.Signed)
		{
			numberProgress = 3;
		}
		else
		{
			numberProgress = 4;
		}

		return numberProgress;
	}


	//#endregion


	//#region SETTER

	setModelUserFirstChecker(): void
	{
		const stringModelUserFirstChecker: string = JSON.stringify(this.modelUserFirstChecker);
		this.modelUserFirstChecker = new UserModel();
		this.modelUserFirstChecker.setModelFromString(stringModelUserFirstChecker);
	}

	setModelUserSecondChecker(): void
	{
		const stringModelUserSecondChecker: string = JSON.stringify(this.modelUserSecondChecker);
		this.modelUserSecondChecker = new UserModel();
		this.modelUserSecondChecker.setModelFromString(stringModelUserSecondChecker);
	}

	setModelUserFirstSigner(): void
	{
		const stringModelUserFirstSigner: string = JSON.stringify(this.modelUserFirstSigner);
		this.modelUserFirstSigner = new UserModel();
		this.modelUserFirstSigner.setModelFromString(stringModelUserFirstSigner);
	}

	setModelUserSecondSigner(): void
	{
		const stringModelUserSecondSigner: string = JSON.stringify(this.modelUserSecondSigner);
		this.modelUserSecondSigner = new UserModel();
		this.modelUserSecondSigner.setModelFromString(stringModelUserSecondSigner);
	}

	setModelUserReviewer(): void
	{
		const stringModelUserReviewer: string = JSON.stringify(this.modelUserReviewer);
		this.modelUserReviewer = new UserModel();
		this.modelUserReviewer.setModelFromString(stringModelUserReviewer);
	}

	//#endregion


	//#region VALIDATION

	validateData(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const numberYear: number = new Date().getFullYear();
		modelResponse.setForValidation("Letter general");

		if (this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = "Judul tidak boleh kosong!";
		}
		else if (this.Type == null || this.Type === undefined)
		{
			modelResponse.MessageContent = "Perihal tidak boleh kosong!";
		}
		else if (this.PaymentPriority == null || this.PaymentPriority === undefined)
		{
			modelResponse.MessageContent = "Prioritas pembayaran tidak boleh kosong!";
		}
		else if (this.PaymentType == null || this.PaymentType === undefined)
		{
			modelResponse.MessageContent = "Keterangan pembayaran tidak boleh kosong!";
		}
		else if (this.BudgetYear == null || this.BudgetYear === undefined)
		{
			modelResponse.MessageContent = "Tahun tidak boleh kosong";
		}
		else if (this.BudgetYear < numberYear - 1 || this.BudgetYear > numberYear)
		{
			modelResponse.MessageContent = `Tahun harus berjarak antara ${numberYear - 1} - ${numberYear}`;
		}
		else if (this.FirstCheckerBy == null || this.FirstCheckerBy === undefined)
		{
			modelResponse.MessageContent = "Checker tidak boleh kosong!";
		}
		else if (this.FirstSignerBy == null || this.FirstSignerBy === undefined)
		{
			modelResponse.MessageContent = "Signer tidak boleh kosong!";
		}
		else if (this.SecondSignerBy == null || this.SecondSignerBy === undefined)
		{
			modelResponse.MessageContent = "Final Signer tidak boleh kosong!";
		}
		else if(this.TotalAmount !== undefined && this.TotalAmount > 1000000000)
		{
			modelResponse.MessageContent = "Total keseluruhan transaksi tidak boleh lebih dari 1 Miliar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForSubmission(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.modelSubmission == null || this.modelSubmission === undefined)
		{
			modelResponse.MessageContent = "Model submission tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForDeclaration(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.modelDeclaration == null || this.modelDeclaration === undefined)
		{
			modelResponse.MessageContent = "Model declaration tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	private validateDataForSubmission(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateAddForSubmission();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.modelSubmission?.validateInsert(this.PaymentType ?? ENUM_PAYMENT_TYPE.Cash) ?? new ResponseModel();
		}
		else
		{

		}

		return modelResponse;
	}

	private validateDataForDeclaration(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateAddForDeclaration();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.modelDeclaration?.validateInsert(this.PaymentType ?? ENUM_PAYMENT_TYPE.Cash, this.BranchType ?? ENUM_BRANCH_TYPE.Branch, this.Type ?? ENUM_LETTER_TYPE.DeclarationRealizationDebt) ?? new ResponseModel();
		}
		else
		{

		}

		return modelResponse;
	}

	private validateTransactionForDeclaration(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if (this.modelDeclaration?.listModelDeclarationDetail !== undefined && this.modelDeclaration?.listModelDeclarationDetail.length <= 0)
		{
			modelResponse.MessageContent = "Daftar transaksi tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if (this.modelDeclaration?.listModelDeclarationDetail !== undefined && this.modelDeclaration?.listModelDeclarationDetail.length > 0)
			{
				for (const modelDeclarationVide of this.modelDeclaration?.listModelDeclarationDetail)
				{
					if (modelDeclarationVide.listModelDeclarationVide !== undefined && modelDeclarationVide.listModelDeclarationVide?.length <= 0)
					{
						modelResponse.MessageContent = "Daftar vide tidak boleh kosong!";
						modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					}
					else
					{

					}
				}
			}
		}

		return modelResponse;
	}

	private validateTransactionForSubmission(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if (this.modelSubmission?.listModelSubmissionDetail !== undefined && this.modelSubmission?.listModelSubmissionDetail.length <= 0)
		{
			modelResponse.MessageContent = "Daftar transaksi tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			if (this.modelSubmission?.listModelSubmissionDetail !== undefined && this.modelSubmission?.listModelSubmissionDetail.length > 0)
			{
				for (const modelSubmissionVide of this.modelSubmission?.listModelSubmissionDetail)
				{
					if (modelSubmissionVide.listModelSubmissionVide !== undefined && modelSubmissionVide.listModelSubmissionVide?.length <= 0)
					{
						modelResponse.MessageContent = "Daftar vide tidak boleh kosong!";
						modelResponse.State = ENUM_RESPONSE_STATE.Fail;
					}
					else
					{

					}
				}
			}
		}

		return modelResponse;
	}

	public validateDocumentForDeclaration(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if (this.modelDeclaration?.listModelDeclarationDocument !== undefined && this.modelDeclaration?.listModelDeclarationDocument.length <= 0)
		{
			modelResponse.MessageContent = "Daftar lampiran tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	public validateDocumentForSubmission(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration");

		if (this.modelSubmission?.listModelSubmissionDocument !== undefined && this.modelSubmission?.listModelSubmissionDocument.length <= 0)
		{
			modelResponse.MessageContent = "Daftar lampiran tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateInsertForSubmission(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForInsert();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateData();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateTransactionForSubmission();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateDataForSubmission();
		}
		else
		{

		}

		return modelResponse;
	}

	validateInsertForDeclaration(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForInsert();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateData();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateTransactionForDeclaration();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateDataForDeclaration();
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpdateForSubmmission(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateData();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateTransactionForSubmission();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateDataForSubmission();
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpdateForDeclaration(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateData();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateTransactionForDeclaration();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateDataForDeclaration();
		}
		else
		{

		}

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	private validateTokenForInsert(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.Token != null && this.Token !== undefined && this.Token !== "")
		{
			modelResponse.MessageContent = "Token tidak boleh terisi!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheckForDeclaration(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddForDeclaration();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.modelDeclaration?.validateCheckModelDeclarationDocument() ?? new ResponseModel();
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckForSubmission(): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateTokenForUpdate();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddForSubmission();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.modelSubmission?.validateCheckModelSubmissionDocument() ?? new ResponseModel();
		}
		else
		{

		}

		return modelResponse;
	}

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token dokumen kosong! Silahkan hubungi developer!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token tidak dalam format yang benar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region CLEAR

	private clearForInsert(): void
	{
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner= undefined;
		this.modelUserSecondSigner = undefined;
		this.modelUserMaker = undefined;
		this.modelUserReviewer = undefined;
		this.RejectedBy = undefined;
		this.RejectedOn = undefined;
	}

	clearSubmissionForServer(clear: (listModelSubmissionVide?: Array<SubmissionVideModel>) => void): void
	{
		if (this.modelSubmission !== undefined)
		{
			this.modelDeclaration = undefined;

			if(this.modelSubmission.listModelSubmissionDetail !== undefined)
			{
				for(const modelSubmissionDetail of this.modelSubmission.listModelSubmissionDetail)
				{
					modelSubmissionDetail.modelBankAccount = undefined;
					modelSubmissionDetail.modelCurrency = undefined;
					modelSubmissionDetail.modelBranch = undefined;
					modelSubmissionDetail.modelSubmissionVide = undefined;

					clear(modelSubmissionDetail.listModelSubmissionVide);
				}
			}
			else
			{

			}
		}
		else
		{

		}
	}

	clearDeclarationForServer(clear: (listModelDeclarationVide?: Array<DeclarationVideModel>) => void): void
	{
		if (this.modelDeclaration !== undefined)
		{
			this.modelSubmission = undefined;

			if(this.modelDeclaration.listModelDeclarationDetail !== undefined)
			{
				for(const modelDeclarationDetail of this.modelDeclaration.listModelDeclarationDetail)
				{
					modelDeclarationDetail.modelBankAccount = undefined;
					modelDeclarationDetail.modelCurrency = undefined;
					modelDeclarationDetail.modelBranch = undefined;
					modelDeclarationDetail.modelDeclarationVide = undefined;

					clear(modelDeclarationDetail.listModelDeclarationVide);
				}
			}
			else
			{

			}
		}
		else
		{

		}
	}

	clearSubmissionForInsert(): void
	{
		this.ID = undefined;
		this.CreatedBy = undefined;
		this.CreatedOn = undefined;
		this.UpdatedBy = undefined;
		this.UpdatedOn = undefined;

		this.clearSubmissionForServer((arrayModelSubmissionVide) =>
		{
			if (arrayModelSubmissionVide !== undefined)
			{
				for (const modelSubmissionDetailVide of arrayModelSubmissionVide)
				{
					modelSubmissionDetailVide.modelAccountCharge = undefined;
					modelSubmissionDetailVide.modelCurrency = undefined;
				}
			}
			else
			{

			}
		});

		this.clearForInsert();
	}

	clearDeclarationForInsert(): void
	{
		this.ID = undefined;
		this.CreatedBy = undefined;
		this.CreatedOn = undefined;
		this.UpdatedBy = undefined;
		this.UpdatedOn = undefined;

		this.clearDeclarationForServer((arrayModelDeclarationVide) =>
		{
			if(arrayModelDeclarationVide !== undefined)
			{
				for (const modelDeclarationDetailVide of arrayModelDeclarationVide)
				{
					modelDeclarationDetailVide.modelAccountCharge = undefined;
					modelDeclarationDetailVide.modelCurrency = undefined;
				}
			}
			else
			{

			}
		});

		this.clearForInsert();
	}

	clearSubmissionForSearch(): void
	{
		this.clearSubmissionForServer((arrayModelSubmissionVide) =>
		{
			if (arrayModelSubmissionVide !== undefined)
			{
				arrayModelSubmissionVide = undefined;
			}
			else
			{

			}
		});

		this.clearForInsert();
	}

	clearDeclarationForSearch(): void
	{
		this.clearDeclarationForServer((arrayModelDeclarationVide) =>
		{
			if (arrayModelDeclarationVide !== undefined)
			{
				arrayModelDeclarationVide = undefined;
			}
			else
			{

			}
		});
		this.clearForInsert();
	}

	clearForDownload(): void
	{
		this.modelDeclaration = undefined;
		this.modelSubmission = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
	}

	clearAllForTokenOnly(): void
	{
		this.modelDeclaration = undefined;
		this.modelSubmission = undefined;
		this.modelUserFirstChecker = undefined;
		this.modelUserSecondChecker = undefined;
		this.modelUserFirstSigner = undefined;
		this.modelUserSecondSigner = undefined;
	}

	//#endregion


	//#region GENERATE

	generateBudgetYear(): Array<number>
	{
		const arrayNumberBudgetYear: Array<number> = [];
		const numberYear: number = new Date().getFullYear();

		arrayNumberBudgetYear.push(numberYear - 1);
		arrayNumberBudgetYear.push(numberYear);

		return arrayNumberBudgetYear;
	}

	//#endregion
}

//#endregion