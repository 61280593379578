import { ENUM_LETTER_STATUS } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
//#region IMPORT


//#endregion


//#region CLASS

export class LetterGeneralSummaryReportModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for report model.
		Author: Andryana Baros.
		Created on : Thursday, 23 September 2021. 			Updated on : -.
		Created by : Andryana Baros.						Updated by : -.
		Version : 1.0:2.
	*/

	Title?: string;
	LetterNumber?: string;
	LetterStatus?: ENUM_LETTER_STATUS;
	DateTime?: Date;
	SignedDate?: Date;
	AccountChargeCode?: string;
	AccountChargeName?: string;
	Description?: string;
	Amount?: number;
	RealizationAmount?: number;
	RealizationAndAmount?: any;
	BeneficiaryName?: string;

	/* Attribute - END */


	//#region GETTER

	getStatusName(): string
	{
		if (this.Status != null && this.Status !== undefined)
		{
			if (this.Status === ENUM_LETTER_STATUS.OnProgress)
			{
				return "Sedang Dibuat";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForCheck)
			{
				return "Menunggu Pengecekan";
			}
			else if (this.Status === ENUM_LETTER_STATUS.PendingForSign)
			{
				return "Menunggu Disetujui";
			}
			else if (this.Status === ENUM_LETTER_STATUS.Rejected)
			{
				return "Ditolak";
			}
			else if (this.Status >= ENUM_LETTER_STATUS.Signed)
			{
				return "Disetujui";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	//#endregion
}

//#endregion