//#region IMPORT

import { ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";
import { SubmissionDetailModel } from "./submissiondetail.model";
import { SubmissionDocumentModel } from "./submissiondocument.model";

//#endregion


//#region CLASS

export class SubmissionModel extends BaseModel
{
	ID?: number;
	LetterInquiryID?: number;
	SubmissionNumber?: string;
	ProposedName?: string;
	listModelSubmissionDetail?: Array<SubmissionDetailModel>;
	listModelSubmissionDocument?: Array<SubmissionDocumentModel>;

	constructor()
	{
		super();
		this.listModelSubmissionDetail = [];
		this.listModelSubmissionDocument = [];
	}


	//#region VALIDATION

	validateInsert(enumPaymentType: ENUM_PAYMENT_TYPE): ResponseModel
	{
		let modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if (this.ProposedName == null || this.ProposedName === undefined || this.ProposedName === "")
		{
			modelResponse.MessageContent = "Yang mengajukan tidak boleh kosong!";
		}
		else
		{
			modelResponse = this.validateAddModelSubmissionDetail();
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let modelResponseSubmissionDetail: ResponseModel;

			for (const modelSubmissionDetail of this.listModelSubmissionDetail ?? Array<SubmissionDetailModel>())
			{
				modelResponseSubmissionDetail = modelSubmissionDetail.validateInsert(enumPaymentType);

				if (modelResponseSubmissionDetail.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSubmissionDetail;
				}
				else
				{

				}
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddModelSubmissionDetail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelSubmissionDetail == null || this.listModelSubmissionDetail === undefined)
		{
			modelResponse.MessageContent = "Daftar transaksi tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheckListModelSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(this.listModelSubmissionDocument == null || this.listModelSubmissionDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelSubmissionDocument(arrayFile: Array<File>): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if(arrayFile == null || arrayFile === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			for (let numberIndexRow = 0; numberIndexRow < arrayFile.length; numberIndexRow++)
			{
				for (let numberIndexColumn = 0; numberIndexColumn < arrayFile.length; numberIndexColumn++)
				{
					if (numberIndexRow !== numberIndexColumn)
					{
						if (arrayFile[numberIndexRow].name === arrayFile[numberIndexColumn].name)
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara satu dengan yang lainnya memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
					else
					{

					}
				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			for (const file of arrayFile)
			{
				if (this.listModelSubmissionDocument == null || this.listModelSubmissionDocument === undefined)
				{

				}
				else
				{
					for (const modelSubmissionDocumentSelected of this.listModelSubmissionDocument)
					{
						if (file.name === (modelSubmissionDocumentSelected.Name ?? "") + "." + (modelSubmissionDocumentSelected.Extension ?? ""))
						{
							modelResponse.MessageContent = "Nama lampiran yang dipilih antara yang sudah ada dan yang di upload memiliki kesamaan! Silahkan pilih kembali!";
							return modelResponse;
						}
						else
						{

						}
					}
				}
			}
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelSubmissionDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let numberSize: number = 0;

			if (this.listModelSubmissionDocument == null || this.listModelSubmissionDocument === undefined || this.listModelSubmissionDocument.length <= 0)
			{
				modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
			}
			else
			{
				let modelResponseSubmissionDocument: ResponseModel = new ResponseModel();

				for (const modelSubmissionDocument of this.listModelSubmissionDocument)
				{
					modelResponseSubmissionDocument = modelSubmissionDocument.validateAdd();

					if (modelResponseSubmissionDocument.State === ENUM_RESPONSE_STATE.Fail)
					{
						return modelResponseSubmissionDocument;
					}
					else
					{
						numberSize += modelSubmissionDocument.Size ?? 0;
					}
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckModelSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelSubmissionDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseSubmissionDocument: ResponseModel;
			let modelSubmissionDocumentCheck: SubmissionDocumentModel;

			for (const modelSubmissionDocument of this.listModelSubmissionDocument ?? [])
			{
				modelSubmissionDocumentCheck = new SubmissionDocumentModel();
				modelSubmissionDocumentCheck.setModelFromObject(modelSubmissionDocument);
				modelResponseSubmissionDocument = modelSubmissionDocumentCheck.validateCheck();

				if (modelResponseSubmissionDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSubmissionDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setListModelSubmissionDetail(): void
	{
		if (this.listModelSubmissionDetail == null || this.listModelSubmissionDetail === undefined)
		{

		}
		else
		{
			if (this.listModelSubmissionDetail.length > 0)
			{
				const arrayModelSubmissionDetailTemporary: Array<SubmissionDetailModel> = this.listModelSubmissionDetail;
				this.listModelSubmissionDetail = [];

				let modelSubmissionDetail: SubmissionDetailModel;

				for (const modelSubmissionDetailTemporary of arrayModelSubmissionDetailTemporary)
				{
					modelSubmissionDetail = new SubmissionDetailModel();
					modelSubmissionDetail.setModelFromObject(modelSubmissionDetailTemporary);
					modelSubmissionDetail.setListModelSubmissionVide();
					this.listModelSubmissionDetail.push(modelSubmissionDetail);
				}
			}
			else
			{

			}
		}
	}

	setListModelSubmissionDocument(arrayModelSubmissionDocument?: Array<SubmissionDocumentModel>): void
	{
		if (arrayModelSubmissionDocument == null || arrayModelSubmissionDocument === undefined)
		{

		}
		else
		{
			if (arrayModelSubmissionDocument.length > 0)
			{
				let modelSubmissionDocument: SubmissionDocumentModel;
				this.listModelSubmissionDocument = [];

				for (const modelSubmissionDocumentTemporary of arrayModelSubmissionDocument)
				{
					modelSubmissionDocument = new SubmissionDocumentModel();
					modelSubmissionDocument.setModelFromObject(modelSubmissionDocumentTemporary);
					this.listModelSubmissionDocument?.push(modelSubmissionDocument);
				}
			}
			else
			{

			}
		}
	}

	//#endregion


	//#region CLEAR

	clearListModelSubmissionDocument(): void
	{
		this.listModelSubmissionDocument = undefined;
	}

	//endregion
}

//#endregion