//#region IMPORT

import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { RuleConstant } from "src/app/constants/rule.constant";
import { ResponseModel } from "../response.model";
import { BaseModel } from "./base.model";

//#endregion


//#region CLASS

export class DashboardBaseModel extends BaseModel
{
	StartDate?: Date;
	EndDate?: Date;
	BranchCode?: string;

	constructor()
	{
		super();

		this.StartDate = new Date();
		this.StartDate = this.StartDate.generateDateToday();
		this.EndDate = new Date();
		this.EndDate = this.EndDate.generateDateToday();
		this.EndDate.setDate(this.EndDate.getDate() + RuleConstant.NUMBER_DASHBOARDDATEPERIOD_RANGE);
	}


	//#region VALIDATION

	validatePeriod(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dashboard");

		if (this.StartDate == null || this.StartDate === undefined)
		{
			modelResponse.MessageContent = "Start date can't be empty.";
		}
		else if (this.EndDate == null || this.EndDate === undefined)
		{
			modelResponse.MessageContent = "End date can't be empty.";
		}
		else if (this.EndDate <= this.StartDate)
		{
			modelResponse.MessageContent = "End date can't be less than Start date.";
		}
		else if (this.EndDate.calculateDayDifference(this.StartDate) !== RuleConstant.NUMBER_DASHBOARDDATEPERIOD_RANGE)
		{
			modelResponse.MessageContent = "Date period need to be " + (RuleConstant.NUMBER_DASHBOARDDATEPERIOD_RANGE + 1) + " days length.";
		}
		else if (this.StartDate.getDay() !== 1)
		{
			modelResponse.MessageContent = "Start date need to be on Monday.";
		}
		else if (this.EndDate.getDay() !== 0)
		{
			modelResponse.MessageContent = "End date need to be on Sunday.";
		}
		else if (this.BranchCode == null || this.BranchCode === undefined || this.BranchCode === "")
		{
			modelResponse.MessageContent = "Branch can't be empty.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	validatePeriodExport(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Dashboard");

		if (this.StartDate == null || this.StartDate === undefined)
		{
			modelResponse.MessageContent = "Rentang awal tanggal tidak boleh kosong.";
		}
		else if (this.EndDate == null || this.EndDate === undefined)
		{
			modelResponse.MessageContent = "Rentang akhir tanggal tidak boleh kosong.";
		}
		else if (this.EndDate <= this.StartDate)
		{
			modelResponse.MessageContent = "Rentang akhir tanggal tidak boleh lebih awal.";
		}
		else if (this.EndDate.calculateDayDifference(this.StartDate) >= 31)
		{
			modelResponse.MessageContent = "Periode tidak boleh dari 1 bulan.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//endregion
}

//#endregion