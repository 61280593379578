//#region IMPORT

import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, ElementRef, ViewChild, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as xlsx from "xlsx";
import { DatePipe } from "@angular/common";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from "@angular/material/core";
import { ExportFunction } from "src/app/functions/export.function";
import { LetterGeneralSummaryModel } from "src/app/models/lettergeneralsummary.model";
import { AccountChargeDivisionModel } from "src/app/models/accountchargedivision.model";
import { ENUM_LETTER_TYPE, ENUM_RESPONSE_STATE } from "../../constants/enum.constant";
import { ResponseCodeConstant } from "../../constants/responsecode.constant";
import { DashboardBaseModel } from "../../models/bases/dashboard.base.model";
import { TableModel } from "../../models/bases/table.model";
import { DashboardLetterGeneralSummaryModel } from "../../models/dashboardlettergeneralsummary.model";
import { LetterGeneralModel } from "../../models/lettergeneral.model";
import { ResponseModel } from "../../models/response.model";
import { AccountChargeService } from "../../services/accountcharge.service";
import { ReportService } from "../../services/report.service";
import { SessionService } from "../../services/session.service";
import { BaseAuthourizeChartComponent } from "../bases/baseauthourizechart.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";
import { LetterGeneralSummaryReportModel } from "../../models/lettergeneralsummaryreport.model";

//#endregion

//#region CONST

export const appDateFormats: MatDateFormats =
{
	parse:
	{
		dateInput: { month: "short", year: "numeric", day: "numeric" },
	},
	display:
	{
		dateInput: "input",
		monthYearLabel: { year: "numeric", month: "numeric" },
		dateA11yLabel: { year: "numeric", month: "long", day: "numeric"
		},
		monthYearA11yLabel: { year: "numeric", month: "long" },
	}
};

//#endregion


//#region CLASS DECLARATION

@Injectable() export class AppDateAdapter extends NativeDateAdapter
{
	// eslint-disable-next-line @typescript-eslint/ban-types
	format(date: Date, displayFormat: Object): string
	{
		if (displayFormat === "input")
		{
			let stringDay: string = date.getUTCDate().toString();
			stringDay = +stringDay < 10 ? "0" + stringDay : stringDay;
			let stringMonth: string = (date.getUTCMonth() + 1).toString();
			stringMonth = +stringMonth < 10 ? "0" + stringMonth : stringMonth;
			const numberYear = date.getFullYear();
			return `${stringDay}/${stringMonth}/${numberYear}`;
		}
		return date.toDateString();
	}
}


//#endregion

//#region  COMPONENT

@Component
(
	{
		selector: "app-report",
		templateUrl: "./report.component.html",
		styleUrls: ["./report.component.sass"],
		providers: [
			DatePipe,
			{provide: DateAdapter, useClass: AppDateAdapter},
			{provide: MAT_DATE_FORMATS, useValue: appDateFormats}
		]
	}
)

//#endregion


//#region CLASS

export class ReportComponent extends BaseAuthourizeChartComponent
{
	//#region  DECLARATION

	@ViewChild("exportToExcell") exportToExcell!: ElementRef;
	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;

	public _modelLetterGeneral: LetterGeneralModel;
	public _modelTable: TableModel = new TableModel();
	public _modelDashboardBase: DashboardBaseModel;
	public _modelDashboardLetterGeneralSummary: DashboardLetterGeneralSummaryModel;
	public _modelDashboardLetterGeneralSummaryRespone: DashboardLetterGeneralSummaryModel;
	public _modelDashboardLetterGeneralSummaryReportModel: LetterGeneralSummaryReportModel;
	public _modelVariableNull = undefined;
	public _modelVariableZero: number = 0;
	public _modelVariableAccountChargeNull?: number;

	public _arrayModelAccountChargeDivision: Array<AccountChargeDivisionModel>;
	public _arrayModelLetterGeneralSummary: Array<LetterGeneralSummaryModel>;

	public _booleanSearchReport: boolean | null;
	public _enumLetterType = ENUM_LETTER_TYPE;
	public _exportToExcell: ExportFunction;

	public _matrixExportExcel: any = [];

	//#endregion


	//#region  CONSSTRUCTOR

	constructor(private _serviceAccountCharge: AccountChargeService, private _serviceReport: ReportService, private _datePipe: DatePipe, serviceSession: SessionService, router: Router, protected _breakpointObserver: BreakpointObserver)
	{
		super(serviceSession, router, _breakpointObserver);

		this._modelLetterGeneral = new LetterGeneralModel();
		this._modelDashboardLetterGeneralSummary = new DashboardLetterGeneralSummaryModel();
		this._modelDashboardLetterGeneralSummaryRespone = new DashboardLetterGeneralSummaryModel();
		this._modelDashboardLetterGeneralSummaryReportModel = new LetterGeneralSummaryReportModel();

		this._arrayModelAccountChargeDivision = [];
		this._arrayModelLetterGeneralSummary = [];

		this._booleanSearchReport = null;
		this._exportToExcell = new ExportFunction();
		this._modelDashboardBase = new DashboardBaseModel();
		this.setStartDate(this._modelDashboardBase.StartDate);
		this.setEndDate(this._modelDashboardBase.EndDate);

	}

	//#endregion


	//#region  INITIALIZATION

	ngOnInit(): void
	{
		this.callselectAccountChargeForDivision(this);
	}

	//#endregion


	//#region SETTER

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart == null || dateStart === undefined)
		{
			this._modelDashboardLetterGeneralSummary.StartDate = undefined;
		}
		else
		{
			this._modelDashboardLetterGeneralSummary.StartDate = dateStart.clearUTC();
		}
	}

	private setEndDate(dateEnd?: Date): void
	{
		if (dateEnd == null || dateEnd === undefined)
		{
			this._modelDashboardLetterGeneralSummary.EndDate = undefined;
		}
		else
		{
			this._modelDashboardLetterGeneralSummary.EndDate = dateEnd.clearUTC();
		}
	}

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;

		this.getReportNextPage();
	}

	//#endregion


	//#region GETTER

	getEndDate(event): void
	{
		this._modelDashboardBase.EndDate = event.value;
		this.setEndDate(this._modelDashboardBase.EndDate);
	};

	getStartDate(event): void
	{
		this._modelDashboardBase.StartDate = event.value;
		this.setStartDate(this._modelDashboardBase.StartDate);
	};

	getReport(): void
	{
		if (this._booleanSearchReport !== null && this._booleanSearchReport !== undefined)
		{
			if (this._booleanSearchReport === false)
			{
				if (this._modelVariableAccountChargeNull === undefined || this._modelVariableAccountChargeNull === null)
				{
					alert("Biaya akun tidak boloh kosong");
				}
				else if (this._modelVariableAccountChargeNull === 0)
				{
					this._modelTable.Page = 1;
					this._modelDashboardLetterGeneralSummary.AccountChargeID = undefined;
					this.callSelectReportDeclaration(this);
				}
				else
				{
					this._modelTable.Page = 1;
					this._modelDashboardLetterGeneralSummary.AccountChargeID = this._modelVariableAccountChargeNull;
					this.callSelectReportDeclaration(this);
				}
			}
			else
			{
				if (this._modelVariableAccountChargeNull === undefined || this._modelVariableAccountChargeNull === null)
				{
					alert("Biaya akun tidak boloh kosong");
				}
				else if (this._modelVariableAccountChargeNull === 0)
				{
					this._modelTable.Page = 1;
					this._modelDashboardLetterGeneralSummary.AccountChargeID = undefined;
					this.callSelectReportSubmission(this);
				}
				else
				{
					this._modelTable.Page = 1;
					this._modelDashboardLetterGeneralSummary.AccountChargeID = this._modelVariableAccountChargeNull;
					this.callSelectReportSubmission(this);
				}
			}
		}
		else
		{
			alert("Tipe laporan tidak boloh kosong");
		}
	}

	getReportNextPage(): void
	{
		if (this._booleanSearchReport !== null && this._booleanSearchReport !== undefined)
		{
			if (this._booleanSearchReport === false)
			{
				if (this._modelVariableAccountChargeNull === undefined || this._modelVariableAccountChargeNull === null)
				{
					alert("Biaya akun tidak boloh kosong");
				}
				else if (this._modelVariableAccountChargeNull === 0)
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = undefined;
					this.callSelectReportDeclaration(this);
				}
				else
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = this._modelVariableAccountChargeNull;
					this.callSelectReportDeclaration(this);
				}
			}
			else
			{
				if (this._modelVariableAccountChargeNull === undefined || this._modelVariableAccountChargeNull === null)
				{
					alert("Biaya akun tidak boloh kosong");
				}
				else if (this._modelVariableAccountChargeNull === 0)
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = undefined;
					this.callSelectReportSubmission(this);
				}
				else
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = this._modelVariableAccountChargeNull;
					this.callSelectReportSubmission(this);
				}
			}
		}
		else
		{
			alert("Tipe laporan tidak boloh kosong");
		}
	}

	getExportToExcel(): void
	{
		if (this._booleanSearchReport !== null)
		{
			if (this._booleanSearchReport === false)
			{
				if (this._modelVariableAccountChargeNull === undefined || this._modelVariableAccountChargeNull === null)
				{
					alert("Biaya akun tidak boloh kosong");
				}
				else if (this._modelVariableAccountChargeNull === 0)
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = undefined;
					this.callExportToExcellDeclaration(this);
				}
				else
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = this._modelVariableAccountChargeNull;
					this.callExportToExcellDeclaration(this);
				}
			}
			else
			{
				if (this._modelVariableAccountChargeNull === undefined || this._modelVariableAccountChargeNull === null)
				{
					alert("Biaya akun tidak boloh kosong");
				}
				else if (this._modelVariableAccountChargeNull === 0)
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = undefined;
					this.callExportToExcellSubmission(this);
				}
				else
				{
					this._modelDashboardLetterGeneralSummary.AccountChargeID = this._modelVariableAccountChargeNull;
					this.callExportToExcellSubmission(this);
				}
			}
		}
		else
		{
			alert("Tipe laporan tidak boloh kosong");
		}
	}

	//#endregion


	//#region WEB SERVICE

	private callselectAccountChargeForDivision(componentCurrent: ReportComponent): void
	{
		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = 3200;
		modelTable.Search = JSON.stringify(new AccountChargeDivisionModel());
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		this._serviceAccountCharge.selectAccountChargeForDivision
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const modelTableResponse: TableModel = new TableModel();
						modelTableResponse.setModelFromString(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();

						if (modelTableResponse.Result !== undefined)
						{
							const arrayModelAccountChargeDivisonTemp = JSON.parse(modelTableResponse.Result);

							let modelAccountChargeDivisionTemp: AccountChargeDivisionModel = new AccountChargeDivisionModel();

							for(const modelAccountChargeDivision of arrayModelAccountChargeDivisonTemp)
							{
								modelAccountChargeDivisionTemp = new AccountChargeDivisionModel();
								modelAccountChargeDivisionTemp.setModelFromObject(modelAccountChargeDivision);
								componentCurrent._arrayModelAccountChargeDivision.push(modelAccountChargeDivisionTemp);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							modelResponse.MessageTitle = "Account Charge";
							modelResponse.MessageContent = "Account charge list empty";
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}

				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectAccountChargeForDivision(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	callSelectReportDeclaration(componentCurrent: ReportComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		let modelResponseReportDeclaration: ResponseModel = new ResponseModel();
		modelResponseReportDeclaration = componentCurrent._modelDashboardLetterGeneralSummary.validatePeriodExport();

		this._modelTable.Search = JSON.stringify(this._modelDashboardLetterGeneralSummary);

		if (modelResponseReportDeclaration.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceReport.selectLetterGeneralByAttributesForDeclarationForCompanySecretariat
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);

							if (componentCurrent._modelTable.Result !== undefined)
							{
								componentCurrent._modelDashboardLetterGeneralSummaryRespone = JSON.parse(componentCurrent._modelTable.Result);

								const arrayModelRiskAppetiteStatementParameterRealizationTemp = JSON.parse(JSON.stringify(componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralSummary));
								componentCurrent._arrayModelLetterGeneralSummary = [];

								let modelLetterGeneralSummary: LetterGeneralSummaryModel;

								for (const modelLetterGeneralSummaryTemp of arrayModelRiskAppetiteStatementParameterRealizationTemp)
								{
									modelLetterGeneralSummary = new LetterGeneralSummaryModel();
									modelLetterGeneralSummary.setModelFromObject(modelLetterGeneralSummaryTemp);
									componentCurrent._arrayModelLetterGeneralSummary.push(modelLetterGeneralSummary);
								}

								componentCurrent._modelTable.Result = undefined;
								componentCurrent._componentTableControlInclude.setButtonState();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectReportDeclaration(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelTable);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseReportDeclaration, () => { });
		}
	}

	callSelectReportSubmission(componentCurrent: ReportComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		let modelResponseReportDeclaration: ResponseModel = new ResponseModel();
		modelResponseReportDeclaration = componentCurrent._modelDashboardLetterGeneralSummary.validatePeriodExport();

		this._modelTable.Search = JSON.stringify(this._modelDashboardLetterGeneralSummary);

		if (modelResponseReportDeclaration.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceReport.selectLetterGeneralByAttributesForSubmissionForCompanySecretariat
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelTable.setModelFromString(modelResponse.Data);
							if (componentCurrent._modelTable.Result !== undefined)
							{
								componentCurrent._modelDashboardLetterGeneralSummaryRespone = JSON.parse(componentCurrent._modelTable.Result);

								const arrayModelRiskAppetiteStatementParameterRealizationTemp = JSON.parse(JSON.stringify(componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralSummary));
								componentCurrent._arrayModelLetterGeneralSummary = [];

								let modelLetterGeneralSummary: LetterGeneralSummaryModel;

								for (const modelLetterGeneralSummaryTemp of arrayModelRiskAppetiteStatementParameterRealizationTemp)
								{
									modelLetterGeneralSummary = new LetterGeneralSummaryModel();
									modelLetterGeneralSummary.setModelFromObject(modelLetterGeneralSummaryTemp);
									componentCurrent._arrayModelLetterGeneralSummary.push(modelLetterGeneralSummary);
								}

								componentCurrent._modelTable.Result = undefined;
								componentCurrent._componentTableControlInclude.setButtonState();
								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectReportDeclaration(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelTable);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseReportDeclaration, () => { });
		}
	}

	callExportToExcellDeclaration(componentCurrent: ReportComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		let modelResponseToExcellDeclaration: ResponseModel = new ResponseModel();
		modelResponseToExcellDeclaration = componentCurrent._modelDashboardLetterGeneralSummary.validatePeriodExport();

		if (modelResponseToExcellDeclaration.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceReport.selectLetterGeneralForDeclarationForCompanySecretariat
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelDashboardLetterGeneralSummaryRespone = JSON.parse(modelResponse.Data);

							const arrayModelRiskAppetiteStatementParameterRealizationTemp = JSON.parse(JSON.stringify(componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary));
							componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary = [];

							let modelLetterGeneralSummaryReport: LetterGeneralSummaryReportModel;

							for (const modelLetterGeneralSummaryReportTemp of arrayModelRiskAppetiteStatementParameterRealizationTemp)
							{
								modelLetterGeneralSummaryReport = new LetterGeneralSummaryReportModel();
								modelLetterGeneralSummaryReport.setModelFromObject(modelLetterGeneralSummaryReportTemp);
								componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary.push(modelLetterGeneralSummaryReport);
							}
							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("URAIAN");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NO DEKLARASI");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("TANGGAL DEKLARASI");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NAMA PENERIMA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NO AKUN");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NAMA AKUN");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NOMINAL");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary !== undefined && componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary.length > 0)
							{
								for(const modelLetterGeneralSummaryResponse of componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "URAIAN" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.Title);

									/* COLUMN "NO DEKLARASI" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.LetterNumber);

									/* COLUMN "TANGGAL DEKLARASI" */
									if(modelLetterGeneralSummaryResponse.SignedDate !== undefined)
									{
										const newDate: Date = new Date(modelLetterGeneralSummaryResponse.SignedDate);
										const stringStartDate = componentCurrent._datePipe.transform(newDate.initiateWithoutUTC(), "dd/MM/yyyy");
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(stringStartDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "NAMA PENERIMA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.BeneficiaryName);

									/* COLUMN "NO AKUN" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.AccountChargeCode);

									/* COLUMN "NAMA AKUN" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.AccountChargeName);

									/* COLUMN "NOMINAL" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.Amount?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Declration");

								/* save to file */
								xlsx.writeFile(workBook, "Report Excel.xlsx");

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary !== undefined && componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () =>
					{
						componentCurrent.callSelectReportDeclaration(componentCurrent);
					});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
					{ componentCurrent.signOut(); });
				}
			}, this._modelDashboardLetterGeneralSummary);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseToExcellDeclaration, () => { });
		}
	}

	callExportToExcellSubmission(componentCurrent: ReportComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		let modelResponseToExcellDeclaration: ResponseModel = new ResponseModel();
		modelResponseToExcellDeclaration = componentCurrent._modelDashboardLetterGeneralSummary.validatePeriodExport();

		if (modelResponseToExcellDeclaration.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceReport.selectLetterGeneralForSubmissionForCompanySecretariat
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._modelDashboardLetterGeneralSummaryRespone = JSON.parse(modelResponse.Data);

							const arrayModelRiskAppetiteStatementParameterRealizationTemp = JSON.parse(JSON.stringify(componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary));
							componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary = [];

							let modelLetterGeneralSummaryReport: LetterGeneralSummaryReportModel;

							for (const modelLetterGeneralSummaryReportTemp of arrayModelRiskAppetiteStatementParameterRealizationTemp)
							{
								modelLetterGeneralSummaryReport = new LetterGeneralSummaryReportModel();
								modelLetterGeneralSummaryReport.setModelFromObject(modelLetterGeneralSummaryReportTemp);
								componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary.push(modelLetterGeneralSummaryReport);
							}

							modelResponse.Data = undefined;

							componentCurrent._matrixExportExcel = [];
							componentCurrent._matrixExportExcel[0] = [];
							let numberIndexRowExcel: number = 0;

							/* SET HEADER EXCEL - START */

							componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("URAIAN");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NO DEKLARASI");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("TANGGAL DEKLARASI");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NAMA PENERIMA");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NO AKUN");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NAMA AKUN");
							componentCurrent._matrixExportExcel[numberIndexRowExcel].push("NOMINAL");

							/* SET HEADER EXCEL - END */

							if (componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary !== undefined && componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary.length > 0)
							{
								for(const modelLetterGeneralSummaryResponse of componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary)
								{
									numberIndexRowExcel++;
									componentCurrent._matrixExportExcel[numberIndexRowExcel] = [];

									/* COLUMN "URAIAN" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.Title);

									/* COLUMN "NO DEKLARASI" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.LetterNumber);

									/* COLUMN "TANGGAL DEKLARASI" */
									if(modelLetterGeneralSummaryResponse.SignedDate !== undefined)
									{
										const newDate: Date = new Date(modelLetterGeneralSummaryResponse.SignedDate);
										const stringStartDate = componentCurrent._datePipe.transform(newDate.initiateWithoutUTC(), "dd/MM/yyyy");
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push(stringStartDate);
									}
									else
									{
										componentCurrent._matrixExportExcel[numberIndexRowExcel].push("-");
									}

									/* COLUMN "NAMA PENERIMA" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.BeneficiaryName);

									/* COLUMN "NO AKUN" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.AccountChargeCode);

									/* COLUMN "NAMA AKUN" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.AccountChargeName);

									/* COLUMN "NOMINAL" */
									componentCurrent._matrixExportExcel[numberIndexRowExcel].push(modelLetterGeneralSummaryResponse.Amount?.convertToCurrency());
								}

								/* generate worksheet */
								const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(componentCurrent._matrixExportExcel, { cellDates: true, dateNF: "dd/mm/yyyy" });

								/* generate workbook and add the worksheet */
								const workBook: xlsx.WorkBook = xlsx.utils.book_new();
								xlsx.utils.book_append_sheet(workBook, workSheet, "Report_Submission");

								/* save to file */
								xlsx.writeFile(workBook, "Report Excel.xlsx");

								const modelResponseExcel: ResponseModel = new ResponseModel();
								modelResponseExcel.MessageTitle = "Ekspor Excel";
								modelResponseExcel.MessageContent = "Pengunduhan data excel sukses! Silahkan tunggu.";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { });

								componentCurrent._functionUserInterface.updateLoadingProgress();
							}
							else if(componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary !== undefined && componentCurrent._modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralReportSummary.length <= 0)
							{
								const modelResponseValidationError: ResponseModel = new ResponseModel();
								modelResponseValidationError.MessageTitle = "Ekspor Excel";
								modelResponseValidationError.MessageContent = "Data dalam file excel kosong! Silahkan hubungi pengembang!";
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseValidationError, () => { });
							}
							else
							{

							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () =>
					{ componentCurrent.callExportToExcellSubmission(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
					{ componentCurrent.signOut(); });
				}
			}, this._modelDashboardLetterGeneralSummary);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseToExcellDeclaration, () => { });
		}
	}

	//#endregion

	//#region FUNCTION

	public clearArrayDisplay(): void
	{
		this._arrayModelLetterGeneralSummary = [];
		this._modelTable = new TableModel();
		this._componentTableControlInclude.setButtonState();
	}

	//#endregion
}

//#endregion



