//#region IMPORT

import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { LegendPosition } from "@swimlane/ngx-charts";
import { NumberConstant } from "src/app/constants/number.constant";
import { DashboardBaseModel } from "src/app/models/bases/dashboard.base.model";
import { ChartItemModel } from "src/app/models/chartitem.model";
import { ChartItemSingleModel } from "src/app/models/libraries/chartitemsingle.model";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "./baseauthourize.component";

//#endregion


//#region CLASS

export class BaseAuthourizeChartComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanLegendShow: boolean = true;
	public _booleanLabelShow: boolean = false;
	public _legendPositionPie: LegendPosition = LegendPosition.Below;
	public _legendPositionBar: LegendPosition = LegendPosition.Right;

	public _arraySizeChartPieMedium: [number, number] = [240, 240];
	public _arraySizeChartPieSmall: [number, number] = [120, 120];
	public _arraySizeChartPieLarge: [number, number] = [480, 480];
	public _arraySizeChartBarLarge: [number, number] = [900, 360];
	public _arraySizeChartBarSmall: [number, number] = [600, 240];
	public _arraySizeChartBarMedium: [number, number] = [300, 120];

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, protected _breakpointObserver: BreakpointObserver)
	{
		super(serviceSession, router);

		this._breakpointObserver.observe(["(max-width: 950px)", "(max-width: 650px)"]).subscribe((stateBreakpoint: BreakpointState) =>
		{
			if (stateBreakpoint.breakpoints["(max-width: 650px)"])
			{
				this._arraySizeChartBarLarge = [300, 360];
				this._arraySizeChartBarSmall = [300, 360];
				this._arraySizeChartBarMedium = [300, 360];
				this._legendPositionBar = LegendPosition.Below;
			}
			else if (stateBreakpoint.breakpoints["(max-width: 950px)"])
			{
				this._arraySizeChartBarLarge = [600, 360];
				this._arraySizeChartBarSmall = [600, 360];
				this._arraySizeChartBarMedium = [600, 360];
				this._legendPositionBar = LegendPosition.Below;
			}
			else
			{
				this._arraySizeChartBarLarge = [900, 360];
				this._arraySizeChartBarSmall = [600, 240];
				this._arraySizeChartBarMedium = [300, 120];
				this._legendPositionBar = LegendPosition.Below;
			}
		});
	}

	//#endregion


	//#region FUNCTION

	generateChartColor(arrayChartItem?: Array<ChartItemModel>): Array<string>
	{
		const arrayChartColor: Array<string> = [];

		if (arrayChartItem !== undefined && arrayChartItem != null)
		{
			if (arrayChartItem.length < 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = (NumberConstant.NUMBER_VALUE_THEME_SECONDARY_DARK_RED - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_RED));
					numberGreen = (NumberConstant.NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_GREEN));
					numberBlue = (NumberConstant.NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE - (numberIndex * NumberConstant.NUMBER_VALUE_THEME_CHART_STEP_BLUE));

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else if (arrayChartItem.length > 10)
			{
				let numberRed: number;
				let numberGreen: number;
				let numberBlue: number;

				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let numberIndex: number = 0; numberIndex < arrayChartItem.length; numberIndex++)
				{
					numberRed = NumberConstant.NUMBER_VALUE_THEME_SECONDARY_DARK_RED;
					numberGreen = NumberConstant.NUMBER_VALUE_THEME_SECONDARY_DARK_GREEN;
					numberBlue = NumberConstant.NUMBER_VALUE_THEME_SECONDARY_DARK_BLUE;

					arrayChartColor.push(this._functionUserInterface.convertRGBToHex(numberRed, numberGreen, numberBlue));
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartColor;
	}

	convertChartItemServerToChartItemSingleLibrary(arrayChartItemServer?: Array<ChartItemModel> | null): Array<ChartItemSingleModel>
	{
		const arrayChartItemSingleLibrary: Array<ChartItemSingleModel> = [];

		if (arrayChartItemServer != null && arrayChartItemServer !== undefined)
		{
			if (arrayChartItemServer.length > 0)
			{
				let modelChartItem: ChartItemSingleModel;

				for (const modelChartItemServer of arrayChartItemServer)
				{
					modelChartItem = new ChartItemSingleModel();
					modelChartItem.name = modelChartItemServer.Label === undefined ? "" : modelChartItemServer.Label;
					modelChartItem.value = modelChartItemServer.Value === undefined ? 0 : modelChartItemServer.Value;
					arrayChartItemSingleLibrary.push(modelChartItem);
				}
			}
			else
			{

			}
		}
		else
		{

		}

		return arrayChartItemSingleLibrary;
	}

	protected generatePeriodWeekly(dateToday: Date): DashboardBaseModel
	{
		const dateStart: Date = new Date();
		dateStart.setDate(dateToday.getDate() - (dateToday.getDay() - 1));
		const dateEnd: Date = new Date();
		dateEnd.setDate(dateToday.getDate() + (7 - dateToday.getDay()));
		const modelDashboardBase: DashboardBaseModel = new DashboardBaseModel();
		modelDashboardBase.StartDate = dateStart;
		modelDashboardBase.EndDate = dateEnd;

		return modelDashboardBase;
	}

	//endregion
}

//#endregion