//#region IMPORT

import { ENUM_LETTER_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { DashboardBaseModel } from "./bases/dashboard.base.model";
import { LetterGeneralSummaryModel } from "./lettergeneralsummary.model";
import { ResponseModel } from "./response.model";
import { LetterGeneralSummaryReportModel } from "./lettergeneralsummaryreport.model";

//#endregion


//#region CLASS

export class DashboardLetterGeneralSummaryModel extends DashboardBaseModel
{
	/*
		Attribute - START
		Description : Attribute for report model.
		Author: Andryana Baros.
		Created on : Webnesday, 15 September 2021. 			Updated on : -.
		Created by : Andryana Baros.						Updated by : -.
		Version : 1.0:2.
	*/

	LetterType?: ENUM_LETTER_TYPE;
	AccountChargeID?: number;
	Amount?: number;
	listModelLetterGeneralSummary?: Array<LetterGeneralSummaryModel>;
	listModelLetterGeneralReportSummary?: Array<LetterGeneralSummaryReportModel>;

	/* Attribute - END */

	constructor()
	{
		super();

		this.listModelLetterGeneralSummary = [];
		this.listModelLetterGeneralReportSummary = [];

	}

	validateLetterGeneralSummary(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter Genaral Summary");
		const modelResponseValidateDate: ResponseModel = this.validatePeriodExport();

		if (modelResponseValidateDate.State === ENUM_RESPONSE_STATE.Success)
		{
			// if (this.LetterType === null)
			// {
			// 	modelResponse.MessageContent = "Letter type Can't be empty.";
			// 	return modelResponse;
			// }
			// else if (this.AccountChargeID == null)
			// {
			// 	modelResponse.MessageContent = "Account Can't be empty.";
			// 	return modelResponse;
			// }
			// else
			// {
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			return modelResponse;
			// }
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			modelResponse.MessageTitle = modelResponseValidateDate.MessageTitle;
			modelResponse.MessageContent = modelResponseValidateDate.MessageContent;
			return modelResponse;
		}
	}

	//#endregion

}

//#endregion