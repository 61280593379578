//#region IMPORT

import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { ResponseModel } from "src/app/models/response.model";
import { TicketDocumentModel } from "src/app/models/ticketdocument.model";
import { TicketdocumentService } from "src/app/services/ticketdocument.service";
import { DeclarationDocumentModel } from "src/app/models/declarationdocument.model";
import { LetterGeneralModel } from "src/app/models/lettergeneral.model";
import { SubmissionDocumentModel } from "src/app/models/submissiondocument.model";
import { DeclarationService } from "src/app/services/declaration.service";
import { SubmissionService } from "src/app/services/submission.service";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-download",
	templateUrl: "./download.component.html",
	styleUrls: ["./download.component.sass"]
})

//#endregion


//#region CLASS

export class DownloadComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelLetterGeneralDownloadRequest: LetterGeneralModel;
	public _modelDeclarationDocumentRequest: DeclarationDocumentModel;
	public _modelSubmissionDocumentRequest: SubmissionDocumentModel;
	public _modelTicketDocumentRequest: TicketDocumentModel;

	//#endregion

	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private _serviceDeclaration: DeclarationService, private _serviceSubmission: SubmissionService, private _serviceTicketDocument: TicketdocumentService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelLetterGeneralDownloadRequest = new LetterGeneralModel();
		this._modelLetterGeneralDownloadRequest.clearForDownload();
		this._modelDeclarationDocumentRequest = new DeclarationDocumentModel();
		this._modelSubmissionDocumentRequest = new SubmissionDocumentModel();
		this._modelTicketDocumentRequest = new TicketDocumentModel();
	}

	//#endregion


	//#region INITIALIZER

	ngOnInit(): void
	{
		this.getDocumentTokenAndCategoryFromURLParameter();
		this.callDownload();
	}

	//#endregion


	//#region WEB SERVICE

	callDownload(): void
	{
		if (this._stringCategory.includes("downloadDeclarationLetter"))
		{
			this.callDownloadDeclarationLetter(this);
		}
		else if (this._stringCategory.includes("downloadDeclarationAttachment"))
		{
			this.callDownloadDeclarationAttachment(this);
		}
		else if (this._stringCategory.includes("downloadSubmissionLetter"))
		{
			this.callDownloadSubmissionLetter(this);
		}
		else if (this._stringCategory.includes("downloadSubmissionAttachment"))
		{
			this.callDownloadSubmissionAttachment(this);
		}
		else if (this._stringCategory.includes("downloadTicketAttachment"))
		{
			this.callDownloadTicketAttachment(this);
		}
	}

	callDownloadDeclarationLetter(componentCurrent: DownloadComponent): void
	{
		this._functionUserInterface.setLoadingProgress(3);
		this._modelLetterGeneralDownloadRequest = new LetterGeneralModel();
		this._modelLetterGeneralDownloadRequest.clearForDownload();
		this._modelLetterGeneralDownloadRequest.Token = componentCurrent._stringDocumentToken;

		this._serviceDeclaration.downloadDeclarationLetter
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelLetterGeneralResponse: LetterGeneralModel = new LetterGeneralModel();
					modelLetterGeneralResponse.clearForDownload();
					modelLetterGeneralResponse.setModelFromString(modelResponse.Data);

					if (modelLetterGeneralResponse !== undefined)
					{
						if (modelLetterGeneralResponse.FileName == null || modelLetterGeneralResponse.FileName === undefined || modelLetterGeneralResponse.Data == null || modelLetterGeneralResponse.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlob(modelLetterGeneralResponse.FileName, "application/pdf", ".pdf", modelLetterGeneralResponse.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadDeclarationLetter(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadDeclarationLetter"); });
			}
		}, componentCurrent._modelLetterGeneralDownloadRequest);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	callDownloadDeclarationAttachment(componentCurrent: DownloadComponent): void
	{
		this._functionUserInterface.setLoadingProgress(3);
		this._modelDeclarationDocumentRequest = new DeclarationDocumentModel();
		this._modelDeclarationDocumentRequest.Token = componentCurrent._stringDocumentToken;

		this._serviceDeclaration.downloadDeclarationAttachment
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelDeclarationDocumentResponse: DeclarationDocumentModel = new DeclarationDocumentModel();
					modelDeclarationDocumentResponse.setModelFromString(modelResponse.Data);

					if (modelDeclarationDocumentResponse !== undefined)
					{
						// eslint-disable-next-line max-len
						if (modelDeclarationDocumentResponse.Name == null || modelDeclarationDocumentResponse.Name === undefined || modelDeclarationDocumentResponse.Format == null || modelDeclarationDocumentResponse.Format === undefined || modelDeclarationDocumentResponse.Extension == null || modelDeclarationDocumentResponse.Extension === undefined || modelDeclarationDocumentResponse.Data == null || modelDeclarationDocumentResponse.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlob(modelDeclarationDocumentResponse.Name, modelDeclarationDocumentResponse.Format, modelDeclarationDocumentResponse.Extension, modelDeclarationDocumentResponse.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadDeclarationAttachment(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadDeclarationAttachment"); });
			}
		}, this._modelDeclarationDocumentRequest);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	callDownloadSubmissionLetter(componentCurrent: DownloadComponent): void
	{
		this._functionUserInterface.setLoadingProgress(3);
		this._modelLetterGeneralDownloadRequest = new LetterGeneralModel();
		this._modelLetterGeneralDownloadRequest.clearForDownload();
		this._modelLetterGeneralDownloadRequest.Token = componentCurrent._stringDocumentToken;

		this._serviceSubmission.downloadSubmissionLetter
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelLetterGeneralResponse: LetterGeneralModel = new LetterGeneralModel();
					modelLetterGeneralResponse.clearForDownload();
					modelLetterGeneralResponse.setModelFromString(modelResponse.Data);

					if (modelLetterGeneralResponse !== undefined)
					{
						if (modelLetterGeneralResponse.FileName == null || modelLetterGeneralResponse.FileName === undefined || modelLetterGeneralResponse.Data == null || modelLetterGeneralResponse.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlob(modelLetterGeneralResponse.FileName, "application/pdf", ".pdf", modelLetterGeneralResponse.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadSubmissionLetter(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadSubmissionletter"); });
			}
		}, componentCurrent._modelLetterGeneralDownloadRequest);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	callDownloadSubmissionAttachment(componentCurrent: DownloadComponent): void
	{
		this._functionUserInterface.setLoadingProgress(3);
		this._modelSubmissionDocumentRequest = new SubmissionDocumentModel();
		this._modelSubmissionDocumentRequest.Token = componentCurrent._stringDocumentToken;

		this._serviceSubmission.downloadSubmissionAttachment
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelSubmissionDocumentResponse: SubmissionDocumentModel = new SubmissionDocumentModel();
					modelSubmissionDocumentResponse.setModelFromString(modelResponse.Data);

					if (modelSubmissionDocumentResponse !== undefined)
					{
						// eslint-disable-next-line max-len
						if (modelSubmissionDocumentResponse.Name == null || modelSubmissionDocumentResponse.Name === undefined || modelSubmissionDocumentResponse.Format == null || modelSubmissionDocumentResponse.Format === undefined || modelSubmissionDocumentResponse.Extension == null || modelSubmissionDocumentResponse.Extension === undefined || modelSubmissionDocumentResponse.Data == null || modelSubmissionDocumentResponse.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlob(modelSubmissionDocumentResponse.Name, modelSubmissionDocumentResponse.Format, modelSubmissionDocumentResponse.Extension, modelSubmissionDocumentResponse.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadSubmissionAttachment(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadSubmissionAttachment"); });
			}
		}, this._modelSubmissionDocumentRequest);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	callDownloadTicketAttachment(componentCurrent: DownloadComponent): void
	{
		this._functionUserInterface.setLoadingProgress(3);
		this._modelTicketDocumentRequest = new TicketDocumentModel();
		this._modelTicketDocumentRequest.Token = componentCurrent._stringDocumentToken;

		this._serviceTicketDocument.downloadTicketDocument
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelTicketDocumentResponse: TicketDocumentModel = new TicketDocumentModel();
					modelTicketDocumentResponse.setModelFromString(modelResponse.Data);

					if (modelTicketDocumentResponse !== undefined)
					{
						// eslint-disable-next-line max-len
						if (modelTicketDocumentResponse.Name == null || modelTicketDocumentResponse.Name === undefined || modelTicketDocumentResponse.Format == null || modelTicketDocumentResponse.Format === undefined || modelTicketDocumentResponse.Extension == null || modelTicketDocumentResponse.Extension === undefined || modelTicketDocumentResponse.Data == null || modelTicketDocumentResponse.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlob(modelTicketDocumentResponse.Name, modelTicketDocumentResponse.Format, modelTicketDocumentResponse.Extension, modelTicketDocumentResponse.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadTicketAttachment(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadTicketAttachment"); });
			}
		}, this._modelTicketDocumentRequest);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	//#endregion
}

//#endregion