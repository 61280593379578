//#region IMPORT

import { ENUM_LETTER_STATUS, ENUM_LETTER_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class LetterGeneralSummaryModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for report model.
		Author: Andryana Baros.
		Created on : Webnesday, 15 September 2021. 			Updated on : -.
		Created by : Andryana Baros.						Updated by : -.
		Version : 1.0:2.
	*/

	LetterType?: ENUM_LETTER_TYPE;
	LetterStatus?: ENUM_LETTER_STATUS;
	AccountChargeName?: string;
	AccountChargeCode?: string;
	SignedDate?: Date;
	Amount?: number;
	RealizationAmount?: number;
	BeneficiaryName?: string;

	/* Attribute - END */


	//#region GETTER

	getStatusName(): string
	{
		if (this.LetterStatus != null && this.LetterStatus !== undefined)
		{
			if (this.LetterStatus === ENUM_LETTER_STATUS.OnProgress)
			{
				return "Sedang Dibuat";
			}
			else if (this.LetterStatus === ENUM_LETTER_STATUS.PendingForCheck)
			{
				return "Menunggu Pengecekan";
			}
			else if (this.LetterStatus === ENUM_LETTER_STATUS.PendingForSign)
			{
				return "Menunggu Disetujui";
			}
			else if (this.LetterStatus === ENUM_LETTER_STATUS.Rejected)
			{
				return "Ditolak";
			}
			else if (this.LetterStatus >= ENUM_LETTER_STATUS.Signed)
			{
				return "Disetujui";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	//#endregion
}

//#endregion