//#region IMPORT

import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "./baseauthourize.component";

//#endregion


//#region CLASS

export class BaseAuthourizeDetailComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanReadOnly: boolean;

	public _stringDocumentToken: string = "";
	public _stringCategory: string = "";
	public _arrayStringURL: Array<string> = [];

	protected _routeActivated: ActivatedRoute;
	protected _location: Location;

	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._routeActivated = routeActivated;
		this._location = location;
		this._booleanReadOnly = true;
	}

	//#endregion


	//#region GETTER

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getDocumentTokenAndCategoryFromURLParameter(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		this._stringDocumentToken = this._arrayStringURL[(this._arrayStringURL.length - 1)];
		this._stringCategory = this._arrayStringURL[(this._arrayStringURL.length - 2)];
	}

	protected getSecondTokenFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN2): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getParameterTokenAndStringCategoryFromURLParameter(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		this._stringDocumentToken = this._arrayStringURL[(this._arrayStringURL.length - 1)];
		this._stringCategory = this._arrayStringURL[(this._arrayStringURL.length - 2)];
	}

	protected getParameterCategoryFromRouter(stringKey: string): any | null
	{
		const stringParameter: any | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	protected getCategoryFromURL(numberCategory: number): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		this._stringCategory = this._arrayStringURL[(this._arrayStringURL.length - numberCategory)];
	}
	//#endregion


	//#region CHECK

	protected checkInsert(): boolean
	{
		return this._router.url.indexOf("insert") > -1;
	}

	protected confirmationAction(stringText: string): boolean
	{
		const booleanResult: boolean = confirm(stringText);
		return booleanResult;
	}

	//#endregion


	//#region NAVIGATION

	public goToBack(): void
	{
		this._location.back();
	}

	public goToHome(): void
	{
		this._router.navigate([""]);
	}

	//#endregion
}

//#endregion